import { IDedicatedIBANFeature, IFeatures } from 'types/features';
import {
  IMenhirDedicatedIBANFeature,
  IMenhirFeatures,
} from 'types/menhir/features';

export const transformFeatures = (features: IMenhirFeatures): IFeatures => ({
  FF_BUDGET_WITH_APPLICATION: Boolean(features.budget_with_application),
  FF_CARD: Boolean(features.card),
  FF_FILE_BANKING_PROVIDER: Boolean(features.file_banking_provider),
  FF_REIMBURSEMENT_HISTORY_ENABLED: Boolean(
    features.reimbursement_history_enabled
  ),
  FF_UPSELLING_INSURANCE_BANNER: Boolean(
    features.ff_upselling_insurance_banner
  ),
  FF_USE_NEW_REONBOARDING: Boolean(features.use_new_reonboarding),
  FF_WORKERS_COMPENSATION_DEACTIVATION: Boolean(
    features.ff_workers_compensation_deactivation
  ),
  FF_UPSELLING_INSURANCE_WALLET_BANNER: Boolean(
    features.ff_upselling_insurance_wallet_banner
  ),
  FF_UPSELLING_INSURANCE_BANNER_VARIATION: Boolean(
    features.ff_upselling_insurance_banner_variation
  ),
  FF_CARD_INVOICING: Boolean(features.ff_card_invoicing),
  FF_FRINGE_INVOICED_IN_ADVANCE: Boolean(
    features.ff_fringe_invoiced_in_advance
  ),
  FF_INCLUDE_EMPLOYEE_NUMBER_IN_REPORTS: Boolean(
    features.ff_include_employee_number_in_reports
  ),
  FF_BULK_OFFBOARDING: Boolean(features.ff_bulk_offboarding),
  FF_CAN_ADD_FAMILY_MEMBERS: Boolean(features.ff_can_add_family_members),
  FF_IMPROVE_QUERY_PERFORMANCE: Boolean(features.ff_improve_query_performance),
  FF_LLUNI_INTEGRATION: Boolean(features.ff_lluni_integration),
  FF_ES_REPORTS_PAGE: Boolean(features.ff_es_reports_page),
});

export const transformDedicatedIBANFeatureResponse = (
  feature: IMenhirDedicatedIBANFeature
): IDedicatedIBANFeature => ({
  newFlow: feature.new_flow,
  news: {
    show: feature.news.show,
    date: feature.news.date,
  },
});
