import { IIntegrations } from 'types/integrations';
import { IMenhirIntegrations } from 'types/menhir';

const transformIntegrations = (
  integrations: IMenhirIntegrations
): IIntegrations => ({
  cubejs: {
    token: integrations?.cubejs?.token ?? '',
  },
  zendesk: {
    liveChatToken: integrations?.zendesk?.live_chat_token ?? '',
  },
});

export default {
  transformIntegrations,
};
