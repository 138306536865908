import { IChangePriceEstimations } from 'types/billing';
import {
  IMenhirSubscriptionEnrollment,
  IMenhirSubscriptionEnrollmentEmployee,
  IMenhirUpdatedSubscription,
  MenhirChangePriceEstimations,
  MenhirSubscription,
  MenhirSubscriptionPlan,
} from 'types/menhir';
import {
  ISubscription,
  ISubscriptionEnrollment,
  ISubscriptionEnrollmentEmployee,
  ISubscriptionPlan,
  IUpdatedSubscription,
} from 'types/subscription';

const transformSubscriptionPlan = (
  menhirPlan: MenhirSubscriptionPlan
): ISubscriptionPlan => {
  return {
    id: menhirPlan.id,
    prices: {
      monthly: menhirPlan.monthly_price,
      yearly: menhirPlan.yearly_price,
    },
    discount: menhirPlan.discount
      ? {
          prices: {
            monthly: menhirPlan.discount.monthly_price,
            yearly: menhirPlan.discount.yearly_price,
          },
        }
      : undefined,
    tier: menhirPlan.tier,
    slug: menhirPlan.name,
  };
};

const getSubscriptionCost = (menhirSubscription: MenhirSubscription) => ({
  amount: menhirSubscription.next_bill_summary.simple_cost.amount,
  vat: {
    amount: menhirSubscription.next_bill_summary.simple_cost.vat.amount,
    rate: menhirSubscription.next_bill_summary.simple_cost.vat.rate,
  },
  amountWithVat:
    menhirSubscription.next_bill_summary.simple_cost.amount_with_vat,
  enrollmentAmount:
    menhirSubscription.next_bill_summary.simple_cost.enrollment_amount,
  cardAmount: menhirSubscription.next_bill_summary.simple_cost.card_amount,
  subscriptionAmountWithVat:
    menhirSubscription.next_bill_summary.simple_cost
      .subscription_amount_with_vat,
});

const transformChangePriceEstimations = (
  menhirChangePriceEstimations: MenhirChangePriceEstimations
): IChangePriceEstimations => {
  return {
    endAt: menhirChangePriceEstimations.end_at,
    startAt: menhirChangePriceEstimations.start_at,
    period: menhirChangePriceEstimations.period,
    enrollmentAmount: menhirChangePriceEstimations.enrollment_amount,
    prorataInvoiceEstimate:
      menhirChangePriceEstimations.prorata_invoice_estimate
        ? menhirChangePriceEstimations.prorata_invoice_estimate
        : null,
    nextInvoiceEstimate: menhirChangePriceEstimations.next_invoice_estimate
      ? menhirChangePriceEstimations.next_invoice_estimate
      : null,
  };
};

export const transformSubscription = (
  menhirSubscription: MenhirSubscription
): ISubscription => {
  // TODO: Remove backwards compatibility
  const simpleCost = getSubscriptionCost(menhirSubscription);
  return {
    id: menhirSubscription.id,
    plan: transformSubscriptionPlan(menhirSubscription.plan),
    employeesEnrolled: menhirSubscription.employee_enrollments_count ?? 0,
    active: menhirSubscription.active,
    period: menhirSubscription.period,
    lastInvoice: menhirSubscription.last_invoice
      ? {
          id: menhirSubscription.last_invoice.id,
          status: menhirSubscription.last_invoice.status,
          amount: menhirSubscription.last_invoice.amount,
          dueDate: menhirSubscription.last_invoice.due_date,
          description: menhirSubscription.last_invoice.description,
          invoiceUrl: menhirSubscription.last_invoice.invoice_url,
          vat: {
            rate: Number(menhirSubscription.last_invoice.vat.rate),
            amount: menhirSubscription.last_invoice.vat.amount,
          },
        }
      : undefined,
    nextBillSummary: {
      accumulatedCredit:
        menhirSubscription.next_bill_summary.accumulated_credit_amount,
      nextBillingDate: menhirSubscription.next_bill_summary.next_billing_date,
      nextInvoiceEstimate: menhirSubscription.next_bill_summary
        .next_invoice_estimated
        ? menhirSubscription.next_bill_summary.next_invoice_estimated
        : null,
      renewalDate: menhirSubscription.next_bill_summary.renewal_date,
      changePriceEstimations: menhirSubscription.next_bill_summary
        .change_price_estimations
        ? transformChangePriceEstimations(
            menhirSubscription.next_bill_summary.change_price_estimations
          )
        : null,
      simpleCost,
    },
    cancellationRequested: menhirSubscription.cancellation_requested,
    dueAmount: menhirSubscription.due_amount,
  };
};

const transformSubscriptionEnrollmentEmployee = (
  employee: IMenhirSubscriptionEnrollmentEmployee
): ISubscriptionEnrollmentEmployee => ({
  id: employee.id,
  personId: employee.person_id,
  name: employee.name,
  email: employee.email,
  // TODO: deprecate this taxValue
  taxValue: employee.tax_id?.value,
  taxId: employee.tax_id,
  phone: employee?.phone ?? '',
  gender: employee.gender ?? '',
  birthdate: employee.birthdate ?? '',
  nationality: employee.nationality ?? '',
  status: employee.status,
  offboarding: employee.offboarding
    ? {
        leavingDate: employee.offboarding.leaving_date,
      }
    : undefined,
  hiredAt: employee.hired_at,
});

const transformSubscriptionEnrollment = (
  menhirSubscriptionEnrollment: IMenhirSubscriptionEnrollment
): ISubscriptionEnrollment => ({
  id: menhirSubscriptionEnrollment.id,
  active: menhirSubscriptionEnrollment.active,
  subscriptionId: menhirSubscriptionEnrollment.subscription_id,
  employee: transformSubscriptionEnrollmentEmployee(
    menhirSubscriptionEnrollment.employee
  ),
  employeeId: menhirSubscriptionEnrollment.employee_id,
  startedAt: menhirSubscriptionEnrollment.started_at,
  endedAt: menhirSubscriptionEnrollment.ended_at,
  lastInvoiceAt: menhirSubscriptionEnrollment.last_invoice_at,
});

const transformUpdatedSubscription = (
  updatedSubscription: IMenhirUpdatedSubscription
): IUpdatedSubscription => {
  return {
    enrollments: updatedSubscription.enrollments.map(
      transformSubscriptionEnrollment
    ),
    nextInvoiceDate: updatedSubscription.next_invoice_date,
    proRata: updatedSubscription.prorata,
  };
};

export default {
  transformSubscriptionPlan,
  transformSubscription,
  transformSubscriptionEnrollment,
  transformUpdatedSubscription,
};
