import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Button,
  IconCloseRegular,
  IconEnvelope,
} from '@coverflex-tech/hypernova';
import AnalyticsService from 'services/analytics';
import AuthService from 'services/auth';
import { ISignupManager } from 'types/auth';
import css from './email-verification-mobile.module.css';

interface IProps {
  success?: boolean;
  manager?: ISignupManager;
  token?: string;
}

const EmailVerificationMobile = ({ manager, token, success }: IProps) => {
  const { t } = useTranslation();
  const [resent, setResent] = useState(false);

  const onResend = async () => {
    AnalyticsService.trackEvent(
      'email_verification_resend_link_button_clicked'
    );
    try {
      if (manager) {
        await AuthService.resendEmailConfirmation(manager.id);
      } else if (typeof token === 'string') {
        await AuthService.resendEmailToken(token);
      }
      setResent(true);
    } catch {}
  };

  const content = useMemo(() => {
    if (success) {
      return {
        title: t('self_signup.verify.mobile.title_success'),
        description: t('self_signup.verify.mobile.description_success'),
      };
    }

    return {
      title: t('self_signup.verify.mobile.title'),
      description: (
        <Trans
          i18nKey={'self_signup.verify.mobile.description'}
          values={{ email: manager?.email }}
        />
      ),
    };
  }, [manager, success, t]);

  useEffect(() => {
    if (resent) {
      setTimeout(() => {
        setResent(false);
      }, 5000);
    }
  }, [resent]);

  return (
    <div className={css.host}>
      {resent && (
        <div className={css.toast}>
          <IconEnvelope className={css.toastIcon} />
          <span className={css.toastMessage}>
            {t('self_signup.verify.mobile.toast_message')}
          </span>
          <button className={css.toastClose} onClick={() => setResent(false)}>
            <IconCloseRegular />
          </button>
        </div>
      )}
      <div className={css.iconBadge}>
        <IconEnvelope />
      </div>
      <div className={css.title}>{content.title}</div>
      <div className={css.description}>{content.description}</div>
      <div className={css.disclaimer}>
        <Trans i18nKey="self_signup.verify.mobile.disclaimer">
          <Button
            variant="tertiary"
            size="sm"
            className={css.resendBtn}
            onClick={onResend}
          >
            &nbsp;
          </Button>
        </Trans>
      </div>
    </div>
  );
};

export default EmailVerificationMobile;
