import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { notify } from 'utils/toast/toast';

interface ILocationState {
  newCompanyAccess?: boolean;
}

const NotificationCatcher = () => {
  const { t } = useTranslation();
  const location = useLocation<ILocationState>();

  useEffect(() => {
    if (location?.state?.newCompanyAccess) {
      notify({
        message: t('dashboard.home.notifications.new_company_access.message'),
        type: 'success',
      });
    }
  }, [location, t]);

  return null;
};

export default NotificationCatcher;
