import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Alert } from '@coverflex-tech/hypernova';
import AnalyticsService from 'services/analytics';
import AuthService from 'services/auth';
import { ISignupManager } from 'types/auth';
import css from './email-verification.module.css';

type Props = {
  manager: ISignupManager;
};

const EmailVerification = ({ manager }: Props) => {
  const [resending, setResending] = useState(false);
  const [success, setSuccess] = useState(false);
  const resendEmail = async () => {
    if (manager && !resending) {
      try {
        AnalyticsService.trackEvent(
          'email_verification_resend_link_button_clicked'
        );
        setResending(true);
        await AuthService.resendEmailConfirmation(manager.id);
        setSuccess(true);
      } catch {
      } finally {
        setResending(false);
      }
    }
  };
  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    }
  }, [success]);

  const { t } = useTranslation();
  return (
    <>
      <div className={css.title}>{t('self_signup.verify.title')}</div>
      <div className={css.description}>
        <Trans
          i18nKey="self_signup.verify.description"
          values={{ email: manager.email }}
        />
      </div>
      {success ? (
        <Alert type="success">{t('self_signup.resend_confirmation')}</Alert>
      ) : (
        <div className={css.disclaimer}>
          <Trans
            i18nKey="self_signup.verify.disclaimer"
            components={[
              <span
                className={css.resend}
                onClick={resendEmail}
                key="disclaimer"
              />,
            ]}
          />
        </div>
      )}
    </>
  );
};

export default EmailVerification;
