import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Alert, Button, Heading, Text } from '@coverflex-tech/hypernova';
import { zodResolver } from '@hookform/resolvers/zod';
import { MOBILE_LANDSCAPE } from 'constants/breakpoints';
import { ROUTES } from 'constants/routes';
import useMediaQuery from 'hooks/useMediaQuery';
import usePageViewTracking from 'hooks/usePageViewTracking';
import { AuthenticationLayout } from 'layouts/AuthenticationLayout/AuthenticationLayout';
import AnalyticsService from 'services/analytics';
import AuthService from 'services/auth';
import { ISigninResponse } from 'types/auth';
import { ControlledPasswordField } from 'components/Fields/ControlledPasswordField';
import { ControlledTextField } from 'components/Fields/ControlledTextField';
import { ControllerContext } from 'components/Fields/ControllerContext';
import MobileLogin from './components/MobileLogin';
import SessionExpiredModal from './components/SessionExpiredModal';
import { formSchema } from './schema';
import { css } from './LoginForm.css';

interface ILocationState {
  origin?: 'signup';
  sessionExpired?: boolean;
  verified?: boolean;
}

interface IFeedback {
  type: 'error' | 'success';
  message: string;
}

const getFeedbackMessage = (
  locationState: ILocationState,
  t: TFunction
): IFeedback | null => {
  if (locationState?.verified) {
    return {
      type: 'success',
      message: t('signin.account_verified'),
    };
  } else if (locationState?.origin === 'signup') {
    return {
      type: 'success',
      message: t('signin.password_created'),
    };
  }

  return null;
};

interface IProps {
  onSubmit: (response: ISigninResponse) => void;
}

export const LoginForm = ({ onSubmit }: IProps) => {
  const location = useLocation<ILocationState>();
  const history = useHistory();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(MOBILE_LANDSCAPE);

  usePageViewTracking('login_page');
  const [displaySessionExpiredModal, toggleSessionExpiredModal] =
    useState(false);
  const [feedback, setFeedback] = useState<IFeedback | null>(() =>
    getFeedbackMessage(location.state, t)
  );

  useEffect(() => {
    if (location?.state?.sessionExpired) {
      AnalyticsService.trackEvent('login_session_expired');
      toggleSessionExpiredModal(true);
      history.replace(ROUTES.SIGNIN, {});
    }
  }, [location, history]);

  const onFormSubmit = async (form: { email: string; password: string }) => {
    const { email, password } = form;
    setFeedback(null);
    try {
      const response = await AuthService.signIn({ email, password });
      onSubmit(response);
    } catch {
      setFeedback({
        type: 'error',
        message: t('signin.errors.unauthorized'),
      });
    }
  };

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: zodResolver(formSchema),
  });

  return (
    <div className={css.wrapper}>
      {isMobile ? (
        <MobileLogin verified={location.state?.verified} />
      ) : (
        <AuthenticationLayout>
          <div className={css.host}>
            {displaySessionExpiredModal && (
              <SessionExpiredModal
                onClose={() => toggleSessionExpiredModal(false)}
              />
            )}
            <Heading level={3}>{t('signin.title')}</Heading>
            <FormProvider {...methods}>
              <form
                className={css.form}
                onSubmit={methods.handleSubmit(onFormSubmit)}
              >
                <ControlledTextField
                  name="email"
                  type="email"
                  label={t('signin.email.label')}
                />
                <ControlledPasswordField
                  name="password"
                  label={t('signin.password.label')}
                />
                <ControllerContext
                  render={({ formState: { isSubmitting } }) => (
                    <>
                      {!isSubmitting && feedback && (
                        <Alert type={feedback.type}>{feedback.message}</Alert>
                      )}
                      <div className={css.actions}>
                        <Button type="submit" loading={isSubmitting}>
                          {t('signin.button')}
                        </Button>
                        <Link to={ROUTES.RESET_PASSWORD}>
                          <Button variant="tertiary" presentational>
                            {t('signin.forgot_password')}
                          </Button>
                        </Link>
                      </div>
                    </>
                  )}
                />
              </form>
            </FormProvider>
            <div className={css.links}>
              <Link to={ROUTES.SSO_SIGNIN}>
                <Text underline bold size="sm">
                  {t('signin.sso_link')}
                </Text>
              </Link>
              {import.meta.env.VITE_FLIGHTDECK_URL && (
                <a href={`${import.meta.env.VITE_FLIGHTDECK_URL}`}>
                  <Text underline bold size="sm">
                    {t('signin.employees_link')}
                  </Text>
                </a>
              )}
            </div>
          </div>
        </AuthenticationLayout>
      )}
    </div>
  );
};
