import { TMarket } from 'constants/markets';
import { LanguageType } from 'types/general';

export const LANG = {
  EN: 'en-GB',
  IT: 'it-IT',
  PT: 'pt-PT',
  ES: 'es-ES',
} satisfies Record<string, LanguageType>;

export const LANGUAGES: LanguageType[] = Object.values(LANG);

export const marketLanguagesMapper: Record<TMarket, LanguageType[]> = {
  it: ['en-GB', 'it-IT'],
  pt: ['en-GB', 'pt-PT'],
  es: ['en-GB', 'es-ES'],
};
