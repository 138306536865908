import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Alert } from '@coverflex-tech/hypernova';
import { MOBILE_LANDSCAPE } from 'constants/breakpoints';
import { ROUTES } from 'constants/routes';
import useMediaQuery from 'hooks/useMediaQuery';
import { AuthenticationLayout } from 'layouts/AuthenticationLayout/AuthenticationLayout';
import { parse } from 'query-string';
import AnalyticsService from 'services/analytics';
import AuthService from 'services/auth';
import ExpiredLinkMobile from './components/ExpiredLinkMobile';
import css from './signup-email-confirmation.module.css';

const SignupEmailConfirmation = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { token } = parse(location.search);
  const [validating, setValidating] = useState(true);
  const [success, setSuccess] = useState(false);
  const isMobile = useMediaQuery(MOBILE_LANDSCAPE);

  useEffect(() => {
    (async () => {
      if (!token || typeof token !== 'string') {
        setValidating(false);
        return;
      }

      try {
        await AuthService.confirmAccount(token);
        AnalyticsService.trackEvent('email_verification_success');
        history.push(ROUTES.SIGNIN, { verified: true });
      } catch {
        AnalyticsService.trackPageView('email_verification_expired_page');
        setValidating(false);
      }
    })();
  });

  const resendEmail = async () => {
    AnalyticsService.trackEvent(
      'email_verification_resend_link_button_clicked'
    );
    try {
      if (typeof token === 'string') {
        await AuthService.resendEmailToken(token);
        setSuccess(true);
      }
    } catch {}
  };

  const tokenString = typeof token === 'string' ? token : '';

  if (validating) {
    return null;
  }

  return (
    <div className={css.wrapper}>
      {isMobile ? (
        <ExpiredLinkMobile token={tokenString} />
      ) : (
        <AuthenticationLayout>
          <div className={css.host}>
            <div className={css.title}>{t('email_confirmation.title')}</div>
            <div className={css.description}>
              <Trans
                i18nKey="email_confirmation.description"
                components={[
                  <span
                    className={css.resend}
                    onClick={resendEmail}
                    key="email_confirmation"
                  />,
                ]}
              />
            </div>
            <div className={css.disclaimer}>
              {t('email_confirmation.disclaimer')}
            </div>
            {success && (
              <Alert type="success">
                {t('self_signup.resend_confirmation')}
              </Alert>
            )}
          </div>
        </AuthenticationLayout>
      )}
    </div>
  );
};

export default SignupEmailConfirmation;
