import { useEffect, useState } from 'react';

const useMediaQuery = (mediaQuery: string) => {
  const [matches, setMatches] = useState(
    !!window.matchMedia(mediaQuery).matches
  );

  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQuery);
    const documentChangeHandler = () => {
      setMatches(!!mediaQueryList.matches);
    };
    try {
      mediaQueryList.addEventListener('change', documentChangeHandler);
    } catch {
      mediaQueryList.addListener(documentChangeHandler);
    }

    documentChangeHandler();
    return () => {
      try {
        mediaQueryList.removeEventListener('change', documentChangeHandler);
      } catch {
        mediaQueryList.removeListener(documentChangeHandler);
      }
    };
  }, [mediaQuery]);

  return matches;
};

export default useMediaQuery;
