import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath } from 'react-router-dom';
import history from 'browser-history';
import { ERRORS } from 'constants/errors';
import { PLAN, TMarket } from 'constants/markets';
import { ROUTES } from 'constants/routes';
import { queryClient } from 'contexts/ReactQueryProvider';
import authDTO from 'dto/auth';
import { invalidateGetUser, useGetUser } from 'hooks/api/auth';
import { getDefaultLang } from 'i18n';
import { VitallyService } from 'integrations/vitally/service';
import {
  AnalyticsService,
  setChatConversationFields,
} from 'services/analytics';
import { AuthService } from 'services/auth';
import localStorageService from 'services/local-storage';
import OAuthService from 'services/oauth';
import { Cache, useSWRConfig } from 'swr';
import { AuthUser, UserInfo } from 'types/auth';
import { LoadingPlaceholder } from 'components/LoadingPlaceholder/LoadingPlaceholder';

const getRedirectRoute = (initialRoute: string) => {
  const allRoutes = Object.values(ROUTES);
  const matchedRoute = allRoutes
    .filter(
      (route) =>
        route.includes('dashboard') || route.includes('digital-archive')
    )
    .find((route) => matchPath(initialRoute, { path: route, exact: true }));
  return matchedRoute ? initialRoute : '';
};

interface IContext {
  authUser?: UserInfo;
  tokenUser: AuthUser | null;
  login: (user: AuthUser) => Promise<void>;
  ssoLogin: (code: string, state: string) => Promise<void>;
  logout: () => void;
  updateSession: (token: string, refreshToken: string) => void;
}

interface ProviderProps {
  children?: ReactNode;
}

export const AuthContext = createContext<IContext>({
  authUser: undefined,
  tokenUser: null,
  login: () => Promise.resolve(),
  ssoLogin: () => Promise.resolve(),
  logout: () => {},
  updateSession: () => {},
});

const getTokenUser = () => {
  const token = localStorageService.getAuthToken();
  if (!token) {
    return null;
  }

  const authData = localStorageService.decodeAuthToken(token);
  return authData ? authDTO.tokenToUser(authData) : null;
};

const clearCache = (cache: Cache) => {
  for (const key of cache.keys()) {
    cache.delete(key);
  }
  queryClient.clear();
};

export const AuthProvider = ({ children }: ProviderProps) => {
  const { cache } = useSWRConfig();
  const { i18n } = useTranslation();
  const redirectRouteRef = useRef(getRedirectRoute(window.location.pathname));
  const [tokenUser, setTokenUser] = useState<AuthUser | null>(() =>
    getTokenUser()
  );
  const [ready, setReady] = useState(!tokenUser);
  const { user, error } = useGetUser({ enabled: !!tokenUser });

  const handleLogin = useCallback(
    async (request: () => Promise<AuthUser>) => {
      try {
        clearCache(cache);
        await request();
        const userData = await AuthService.getUserInfo();
        AnalyticsService.trackEvent('login_success', {
          plan: userData.company.plan === PLAN.SUC_MEAL ? 'meal' : PLAN.WALLET,
          email: userData.user.email,
          company_id: userData.company.id,
        });

        setTokenUser(getTokenUser());
        setReady(false);

        if (redirectRouteRef.current) {
          history.push(redirectRouteRef.current);
        }
      } catch (err) {
        AnalyticsService.trackEvent('login_failed');
        throw err;
      }
    },
    [cache]
  );

  const login = useCallback(
    (authUser: AuthUser) => handleLogin(() => Promise.resolve(authUser)),
    [handleLogin]
  );

  const ssoLogin = useCallback(
    (code: string, state: string) =>
      handleLogin(() => OAuthService.authorize(code, state)),
    [handleLogin]
  );

  const updateSession = useCallback(
    (token: string, refresh_token: string) => {
      localStorageService.updateTokens(token, refresh_token);
      const tokenData = localStorageService.decodeAuthToken(token);
      if (tokenData) {
        localStorageService.updateCompanyId(tokenData.company_id);
      }
      clearCache(cache);
      setTokenUser(getTokenUser());
      invalidateGetUser();
      setReady(false);
    },
    [cache]
  );

  const logout = useCallback(() => {
    AuthService.logout();
    clearCache(cache);
    setReady(true);
    setTokenUser(null);
    redirectRouteRef.current = '';
  }, [cache]);

  useEffect(() => {
    if (user && !ready) {
      i18n.changeLanguage(user.company.language ?? getDefaultLang());
      AnalyticsService.trackIdentification({
        id: user.user.id,
        email: user.user.email,
        companyId: user.company.id,
        companyName: user.company.name,
        market: user.company.market.slug,
      });
      VitallyService.identifyUser({
        user: {
          id: user.user.id,
          email: user.user.email,
        },
        company: {
          id: user.company.id,
          name: user.company.name,
        },
      });
      setReady(true);
    } else if (error) {
      logout();
    }
  }, [user, ready, i18n, error, logout]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const marketParam = urlParams.get('market') as TMarket;
    if (marketParam) {
      setChatConversationFields(marketParam);
    }
  }, []);

  const contextValue = useMemo(
    () => ({
      authUser: user,
      tokenUser,
      login,
      ssoLogin,
      logout,
      updateSession,
    }),
    [user, tokenUser, login, ssoLogin, logout, updateSession]
  );

  if (!ready) {
    return <LoadingPlaceholder />;
  }

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  const contextValue = useContext(AuthContext);
  const isAuthenticated = AuthService.checkIfAuthenticated();

  useEffect(() => {
    if (contextValue.authUser && !isAuthenticated) {
      contextValue.logout();
    }
  }, [contextValue, isAuthenticated]);

  return { ...contextValue, isAuthenticated: !!contextValue.authUser };
};

export function useUser() {
  const { authUser } = useContext(AuthContext);

  if (!authUser) {
    throw new Error(ERRORS.USER_REQUIRED);
  }

  return { user: authUser };
}
