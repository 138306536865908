import { useQuery } from '@tanstack/react-query';
import { queryClient } from 'contexts/ReactQueryProvider';
import { API_ROUTES, AuthService } from 'services/auth';
import { UserInfo } from 'types/auth';
import { UseQueryOptionsExtended } from 'types/general';

export function useGetUser(options: UseQueryOptionsExtended<UserInfo> = {}) {
  const { axiosConfig, ...queryOptions } = options;
  const { data: user, ...rest } = useQuery({
    queryKey: [API_ROUTES.ME],
    queryFn: () => AuthService.getUserInfo(axiosConfig),
    ...queryOptions,
  });

  return { user, ...rest };
}

export function invalidateGetUser() {
  return queryClient.invalidateQueries([API_ROUTES.ME]);
}
