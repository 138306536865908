import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  FloatingDigitsField,
  Heading,
} from '@coverflex-tech/hypernova';
import { AuthenticationLayout } from 'layouts/AuthenticationLayout/AuthenticationLayout';
import AnalyticsService from 'services/analytics';
import { AuthService } from 'services/auth';
import { ISigninResponse, OTPRequiredStatus } from 'types/auth';
import { ResendAction } from './components/ResendAction/ResendAction';
import { css } from './OTPForm.css';

interface IProps {
  info: OTPRequiredStatus;
  onSubmit: (response: ISigninResponse) => void;
}

const testDigits = (digits: string) => /\d{6}$/.test(digits);

export const OTPForm = ({ info, onSubmit }: IProps) => {
  const { t } = useTranslation();
  const [digits, setDigits] = useState('');
  const [isRequesting, setIsRequesting] = useState(false);
  const [trustDevice, setTrustDevice] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async () => {
    if (testDigits(digits)) {
      setIsRequesting(true);
      try {
        const response = await AuthService.signIn({
          email: info.email,
          password: info.password,
          otp: digits,
          trustUserAgent: trustDevice,
        });
        AnalyticsService.trackEvent('login_otp_success', {
          trust_device: trustDevice,
        });
        onSubmit(response);
      } catch {
        setErrorMessage(t('signin.otp.error'));
        setDigits('');
        AnalyticsService.trackEvent('login_otp_failed');
      } finally {
        setIsRequesting(false);
      }
    } else {
      setErrorMessage(t('signin.otp.error'));
    }
  };

  return (
    <AuthenticationLayout>
      <Heading level={3} className={css.title}>
        {t('signin.otp.title', { email: info.email })}
      </Heading>
      <div className={css.fields}>
        <div className={css.digits}>
          <FloatingDigitsField
            id="digits"
            label={t('signin.otp.label')}
            value={digits}
            onChange={(newDigits: string) => {
              setErrorMessage('');
              setDigits(newDigits);
            }}
            disabled={isRequesting}
            error={errorMessage}
          />
        </div>
        <Checkbox
          id="trust-device"
          name={'trust-device'}
          label={t('signin.otp.trust_user_agent_label')}
          variant="descriptive"
          onChange={() => {
            setTrustDevice((td) => !td);
          }}
          checked={trustDevice}
          disabled={isRequesting}
        />
        <Button
          type="submit"
          onClick={() => handleSubmit()}
          className={css.button}
          loading={isRequesting}
        >
          {t('general.navigation.confirm')}
        </Button>
      </div>
      <ResendAction email={info.email} password={info.password} />
    </AuthenticationLayout>
  );
};
