import { MARKET, TMarket } from 'constants/markets';
import { TaxIdType } from 'types/general';
import { IMenhirTaxId } from 'types/menhir';
import { StringUtils } from 'utils/string';

export const validateNif = (nif?: string | null) => {
  // 9 digits
  if (!nif || !/^[0-9]{9}$/.test(nif)) return false;

  // control digit
  const nifArray = nif.split('').map((char) => Number(char));
  const total = nifArray.reduce(
    (acc, val, idx) => (idx < 8 ? acc + nifArray[idx] * (9 - idx) : acc),
    0
  );
  const mod = total % 11;
  return nifArray[8] === (mod < 2 ? 0 : 11 - mod);
};

export const validatePartitaIVACompany = (value?: string | null) => {
  if (!value || !/^[0-9]{11}$/.test(value)) return false;

  return true;
};

export const getTaxIdPlaceholderForMarket = (market: TMarket) => {
  switch (market) {
    case MARKET.IT:
      return '000 000 000 00';
    case MARKET.PT:
    default:
      return '000 000 000';
  }
};

export const getTaxIdTypeForMarket = (
  market: TMarket
): Extract<TaxIdType, 'nif' | 'partita_iva'> => {
  switch (market) {
    case MARKET.IT:
      return 'partita_iva';
    case MARKET.PT:
    default:
      return 'nif';
  }
};

export const formatTaxId = (taxId?: IMenhirTaxId, fallback = '') => {
  if (!taxId) return fallback;
  switch (taxId.type) {
    case 'nif':
      return StringUtils.formatValueByChunks(taxId.value);
    case 'cf':
      return StringUtils.formatCFTaxString(taxId.value);
    default:
      return taxId.value;
  }
};

export const determineTaxIdTypeByMarket = (market: TMarket): TaxIdType => {
  switch (market) {
    case MARKET.IT:
      return 'cf';
    case MARKET.ES:
      return 'es_dni';
    case MARKET.PT:
    default:
      return 'nif';
  }
};
