import { useState } from 'react';
import { useAuth } from 'contexts/AuthProvider';
import AnalyticsService from 'services/analytics';
import { ISigninResponse, OTPRequiredStatus } from 'types/auth';
import { LoginForm } from './containers/LoginForm/LoginForm';
import { OTPForm } from './containers/OTPForm/OTPForm';

export const Login = () => {
  const { login } = useAuth();
  const [OTPInfo, setOTPInfo] = useState<OTPRequiredStatus>();
  const onSubmit = (response: ISigninResponse) => {
    if ('reason' in response) {
      AnalyticsService.trackEvent('login_otp_required');
      setOTPInfo(response);
    } else {
      login(response);
    }
  };

  if (OTPInfo) {
    return <OTPForm info={OTPInfo} onSubmit={onSubmit} />;
  }

  return <LoginForm onSubmit={onSubmit} />;
};
