import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import history from 'browser-history';
import { ROUTES } from 'constants/routes';
import { i18n } from 'i18n';
import { API_ROUTES, AuthService } from 'services/auth';
import { LocalStorage } from 'services/local-storage';

interface IRenewTokenResponse {
  data: {
    data: {
      access_token: string;
      refresh_token: string;
    };
  };
}

const BASE_URL = `${import.meta.env.VITE_MENHIR_API_HOST || ''}/api`;

const configObj = {
  baseURL: BASE_URL,
};

export const api = axios.create(configObj);

export const rawApi = axios.create(configObj);

export const fileApi = axios.create(configObj);

export const apiUrl = (path: string) => `/api${path}`;

let pendingRenewRequest: Promise<IRenewTokenResponse> | null = null;
const refreshToken = async (error: AxiosError) => {
  const { config } = error;

  if (
    config?.url?.includes(API_ROUTES.RENEW) ||
    config?.url?.includes(API_ROUTES.SESSIONS) ||
    config?.url?.includes(API_ROUTES.REGISTRATION)
  ) {
    return;
  }

  const refreshToken = LocalStorage.getRefreshToken();

  if (!pendingRenewRequest) {
    pendingRenewRequest = axios.post<null, IRenewTokenResponse>(
      `${BASE_URL}${API_ROUTES.RENEW}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
        timeout: 5000,
      }
    );
  }

  const { data } = await pendingRenewRequest;
  pendingRenewRequest = null;

  const {
    data: { access_token, refresh_token },
  } = data;

  LocalStorage.updateTokens(access_token, refresh_token);
  return access_token;
};

export const requestInterceptor = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  const authToken = LocalStorage.getAuthToken();

  if (authToken) {
    const companyId = LocalStorage.getCurrentCompanyId();
    config.headers.Authorization = `Bearer ${authToken}`;
    config.headers['x-coverflex-language'] = i18n.language;
    config.headers['x-coverflex-company'] = companyId;
  }

  return config;
};

export const responseSuccessInterceptor = (response: AxiosResponse) =>
  response.data;

export const responseFileSuccessInterceptor = (
  response: AxiosResponse<Blob>
): any => {
  const template = response.data;
  const contentDisposition = response.headers['content-disposition'];
  let filename;
  if (contentDisposition) {
    const result = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
      contentDisposition
    );
    const parsedFileName = result?.[1];
    if (parsedFileName) {
      try {
        filename = JSON.parse(parsedFileName);
      } catch {
        filename = parsedFileName;
      }
    }
  }

  const fileBlob = new Blob([template], { type: template.type });
  const fileUrl = window.URL.createObjectURL(fileBlob);
  return {
    url: fileUrl,
    name: filename,
  };
};

export const responseErrorInterceptor = async (error: AxiosError<any>) => {
  const { response, config } = error;

  if (axios.isCancel(error)) {
    throw error;
  }

  if (response && config) {
    if (response.status === 401) {
      try {
        const authToken = await refreshToken(error);
        if (authToken) {
          return api.request(config);
        }
      } catch {
        if (pendingRenewRequest) {
          pendingRenewRequest = null;
          AuthService.logout();
          history.push(ROUTES.SIGNIN, { sessionExpired: true });
        }
      }
    }

    const { data } = response;
    return Promise.reject({
      statusCode: response?.status,
      errorCode: data.errors?.status,
      errors: data.errors ?? null,
      data,
    });
  }
};

rawApi.interceptors.request.use(requestInterceptor);

api.interceptors.request.use(requestInterceptor);
api.interceptors.response.use(
  responseSuccessInterceptor,
  responseErrorInterceptor
);

fileApi.interceptors.request.use(requestInterceptor);
fileApi.interceptors.response.use(
  responseFileSuccessInterceptor,
  responseErrorInterceptor
);
