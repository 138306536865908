import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthenticationLayout as HypernovaAuthenticationLayout } from '@coverflex-tech/hypernova';

interface IProps {
  children: ReactNode;
}

export const AuthenticationLayout = ({ children }: IProps) => {
  const { t } = useTranslation();

  return (
    <HypernovaAuthenticationLayout
      title={t('authentication.main_title')}
      description={t('authentication.main_description')}
    >
      <>{children}</>
    </HypernovaAuthenticationLayout>
  );
};
