import { Suspense } from 'react';
import { Redirect } from 'react-router-dom';
import { ERRORS } from 'constants/errors';
import { MARKET } from 'constants/markets';
import { ROUTES } from 'constants/routes';
import { useUser } from 'contexts/AuthProvider';
import { safeLazy } from 'utils/import';
import { LoadingPlaceholder } from 'components/LoadingPlaceholder/LoadingPlaceholder';
import NotificationCatcher from 'components/NotificationCatcher';

const { PTOnboarding } = safeLazy(
  () => import('./containers/PTOnboarding/PTOnboarding')
);

const { ITOnboarding } = safeLazy(
  () => import('./containers/ITOnboarding/ITOnboarding')
);

const { ESOnboarding } = safeLazy(
  () => import('./containers/ESOnboarding/ESOnboarding')
);

export const Onboarding = () => {
  const { user } = useUser();

  if (user.company.onboarded) {
    return (
      <Redirect
        to={{
          pathname: ROUTES.DASHBOARD_HOME,
          state: { walletSetupOrigin: 'onboarding' },
        }}
      />
    );
  }

  const renderComponent = () => {
    if (user.company.market.slug === MARKET.PT) {
      return <PTOnboarding />;
    }

    if (user.company.market.slug === MARKET.IT) {
      return <ITOnboarding />;
    }

    if (user.company.market.slug === MARKET.ES) {
      return <ESOnboarding />;
    }

    throw new Error(ERRORS.MARKET_NOT_SUPPORTED);
  };

  return (
    <>
      <NotificationCatcher />
      <Suspense fallback={<LoadingPlaceholder />}>{renderComponent()}</Suspense>
    </>
  );
};
