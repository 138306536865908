import { TMarket } from 'constants/markets';
import {
  ITrackingCustomEventTraits,
  ITrackingPageViewTraits,
  TrackingEventType,
  TrackingPageNameType,
} from 'types/tracking';
import { getIntegrations } from './integrations';

declare global {
  interface Window {
    analytics: any;
    zE: any;
  }
}

interface IIdentifyUser {
  id: string;
  email: string;
  companyId: string;
  companyName: string;
  market: TMarket;
}

const trackIdentification = (userIdentify: IIdentifyUser) => {
  try {
    window.analytics.identify(userIdentify.id, {
      email: userIdentify.email,
    });
    window.analytics.group(userIdentify.companyId, {
      name: userIdentify.companyName,
    });
  } catch {
  } finally {
    authenticateChatUser(userIdentify.market);
  }
};

const trackEvent = <T extends TrackingEventType>(
  type: T,
  metadata?: ITrackingCustomEventTraits[T]
) => {
  try {
    window.analytics.track(type, metadata);
  } catch {
    return null;
  }
};

const trackPageView = <T extends TrackingPageNameType>(
  pageName: T,
  metadata?: ITrackingPageViewTraits[T]
) => {
  try {
    window.analytics.page(pageName, metadata);
  } catch {
    return null;
  }
};

const authenticateChatUser = async (market: TMarket) => {
  if (window.zE) {
    try {
      const integrations = await getIntegrations();
      if (integrations.zendesk.liveChatToken) {
        window.zE(
          'messenger',
          'loginUser',
          (callback: (token: string) => void) => {
            callback(integrations.zendesk.liveChatToken);
          }
        );
        setChatConversationFields(market);
      }
    } catch {}
  }
};

export const logoutChatUser = () => {
  if (window.zE) {
    try {
      window.zE('messenger', 'logoutUser');
    } catch {}
  }
};

const openChat = () => {
  if (window.zE) {
    try {
      window.zE('messenger', 'open');
    } catch {}
  }
};

export const setChatConversationFields = (market: TMarket) => {
  if (window.zE) {
    window.zE('messenger:set', 'conversationFields', [
      {
        id: '19164336063261',
        value: `market_${market}`,
      },
      {
        id: '19164643640221',
        value: 'manager',
      },
    ]);
  }
};

export const AnalyticsService = {
  trackIdentification,
  trackEvent,
  trackPageView,
  logoutChatUser,
  openChat,
};

export default AnalyticsService;
