import { IMenhirSignupManager, MenhirUser, TokenData } from 'types/menhir';
import { AuthUser, ISignupManager, UserInfo } from '../types/auth';
import companyDTO from './company';

const tokenToUser = (tokenUser: TokenData): AuthUser => ({
  id: tokenUser.user_id,
  email: tokenUser.email,
  permissions: tokenUser.permissions,
  companyId: tokenUser.company_id,
  bigCompany: tokenUser.big_company,
});

const transformUserInfo = (menhirUser: MenhirUser): UserInfo => ({
  user: menhirUser.user,
  company: companyDTO.transformCompany(menhirUser.company),
  welcomeCreditsConfig: companyDTO.transformWelcomeCreditsConfig(
    menhirUser.welcome_credits_config
  ),
  welcomeCreditsIsActive: menhirUser.welcome_credits_is_active,
});

const transformSignupManager = (
  menhirManager: IMenhirSignupManager
): ISignupManager => ({
  id: menhirManager.id,
  email: menhirManager.email,
  companyId: menhirManager.company_id,
  accessToken: menhirManager.access_token,
  refreshToken: menhirManager.refresh_token,
});

export default {
  tokenToUser,
  transformUserInfo,
  transformSignupManager,
};
