import { Control, Controller, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  FloatingSelectField,
  type IFloatingSelectFieldProps,
} from '@coverflex-tech/hypernova';

interface IControlledSelectFieldProps<T, K extends {}>
  extends Omit<IFloatingSelectFieldProps<K>, 'id' | 'onChange' | 'value'> {
  name: Path<T>;
  control?: Control;
  onChange?: (id: string, value: K | null) => void;
}

export const ControlledSelectField = <T, K extends {}>({
  name,
  control,
  onChange,
  ...restProps
}: IControlledSelectFieldProps<T, K>) => {
  const { t } = useTranslation();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field }, fieldState }) => (
        <FloatingSelectField
          {...field}
          {...restProps}
          id={name}
          onBlur={() => {}}
          onChange={(_id, value) => {
            field.onChange(value);
            field.onBlur();
            onChange?.(_id, value);
          }}
          error={
            fieldState.error?.message ? t(fieldState.error.message) : undefined
          }
        />
      )}
    />
  );
};
