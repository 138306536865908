export interface ICountrySettings {
  name: Record<string, string>;
  dialCode: string | null;
  iso2: string;
}

export const countriesList: ICountrySettings[] = [
  {
    name: {
      'en-GB': 'Afghanistan',
      'pt-PT': 'Afeganistão',
      'it-IT': 'Afghanistan',
    },
    dialCode: '+93',
    iso2: 'AF',
  },
  {
    name: { 'en-GB': 'Albania', 'pt-PT': 'Albânia', 'it-IT': 'Albania' },
    dialCode: '+355',
    iso2: 'AL',
  },
  {
    name: { 'en-GB': 'Algeria', 'pt-PT': 'Argélia', 'it-IT': 'Algeria' },
    dialCode: '+213',
    iso2: 'DZ',
  },
  {
    name: {
      'en-GB': 'American Samoa',
      'pt-PT': 'Samoa Americana',
      'it-IT': 'Samoa americane',
    },
    dialCode: '+1 684',
    iso2: 'AS',
  },
  {
    name: { 'en-GB': 'Andorra', 'pt-PT': 'Andorra', 'it-IT': 'Andorra' },
    dialCode: '+376',
    iso2: 'AD',
  },
  {
    name: { 'en-GB': 'Angola', 'pt-PT': 'Angola', 'it-IT': 'Angola' },
    dialCode: '+244',
    iso2: 'AO',
  },
  {
    name: { 'en-GB': 'Anguilla', 'pt-PT': 'Anguilla', 'it-IT': 'Anguilla' },
    dialCode: '+1 264',
    iso2: 'AI',
  },
  {
    name: {
      'en-GB': 'Antigua and Barbuda',
      'pt-PT': 'Antígua e Barbuda',
      'it-IT': 'Antigua e Barbuda',
    },
    dialCode: '+1268',
    iso2: 'AG',
  },
  {
    name: { 'en-GB': 'Argentina', 'pt-PT': 'Argentina', 'it-IT': 'Argentina' },
    dialCode: '+54',
    iso2: 'AR',
  },
  {
    name: { 'en-GB': 'Armenia', 'pt-PT': 'Armênia', 'it-IT': 'Armenia' },
    dialCode: '+374',
    iso2: 'AM',
  },
  {
    name: { 'en-GB': 'Aruba', 'pt-PT': 'Aruba', 'it-IT': 'Aruba' },
    dialCode: '+297',
    iso2: 'AW',
  },
  {
    name: { 'en-GB': 'Australia', 'pt-PT': 'Austrália', 'it-IT': 'Australia' },
    dialCode: '+61',
    iso2: 'AU',
  },
  {
    name: { 'en-GB': 'Austria', 'pt-PT': 'Áustria', 'it-IT': 'Austria' },
    dialCode: '+43',
    iso2: 'AT',
  },
  {
    name: {
      'en-GB': 'Azerbaijan',
      'pt-PT': 'Azerbaijão',
      'it-IT': 'Azerbaigian',
    },
    dialCode: '+994',
    iso2: 'AZ',
  },
  {
    name: { 'en-GB': 'Bahamas', 'pt-PT': 'Bahamas', 'it-IT': 'Bahamas' },
    dialCode: '+1 242',
    iso2: 'BS',
  },
  {
    name: { 'en-GB': 'Bahrain', 'pt-PT': 'Bahrein', 'it-IT': 'Bahrein' },
    dialCode: '+973',
    iso2: 'BH',
  },
  {
    name: {
      'en-GB': 'Bangladesh',
      'pt-PT': 'Bangladesh',
      'it-IT': 'Bangladesh',
    },
    dialCode: '+880',
    iso2: 'BD',
  },
  {
    name: { 'en-GB': 'Barbados', 'pt-PT': 'Barbados', 'it-IT': 'Barbados' },
    dialCode: '+1 246',
    iso2: 'BB',
  },
  {
    name: {
      'en-GB': 'Belarus',
      'pt-PT': 'Bielorrússia',
      'it-IT': 'Bielorussia',
    },
    dialCode: '+375',
    iso2: 'BY',
  },
  {
    name: { 'en-GB': 'Belgium', 'pt-PT': 'Bélgica', 'it-IT': 'Belgio' },
    dialCode: '+32',
    iso2: 'BE',
  },
  {
    name: { 'en-GB': 'Belize', 'pt-PT': 'Belize', 'it-IT': 'Belize' },
    dialCode: '+501',
    iso2: 'BZ',
  },
  {
    name: { 'en-GB': 'Benin', 'pt-PT': 'Benin', 'it-IT': 'Benin' },
    dialCode: '+229',
    iso2: 'BJ',
  },
  {
    name: { 'en-GB': 'Bermuda', 'pt-PT': 'Bermudas', 'it-IT': 'Bermuda' },
    dialCode: '+1 441',
    iso2: 'BM',
  },
  {
    name: { 'en-GB': 'Bhutan', 'pt-PT': 'Butão', 'it-IT': 'Bhutan' },
    dialCode: '+975',
    iso2: 'BT',
  },
  {
    name: {
      'en-GB': 'Bosnia and Herzegovina',
      'pt-PT': 'Bósnia e Herzegovina',
      'it-IT': 'Bosnia ed Erzegovina',
    },
    dialCode: '+387',
    iso2: 'BA',
  },
  {
    name: { 'en-GB': 'Botswana', 'pt-PT': 'Botsuana', 'it-IT': 'Botswana' },
    dialCode: '+267',
    iso2: 'BW',
  },
  {
    name: { 'en-GB': 'Brazil', 'pt-PT': 'Brasil', 'it-IT': 'Brasile' },
    dialCode: '+55',
    iso2: 'BR',
  },
  {
    name: {
      'en-GB': 'British Indian Ocean Territory',
      'pt-PT': 'Território Britânico do Oceano Índico',
      'it-IT': 'Territorio britannico dell’Oceano Indiano',
    },
    dialCode: '+246',
    iso2: 'IO',
  },
  {
    name: { 'en-GB': 'Bulgaria', 'pt-PT': 'Bulgária', 'it-IT': 'Bulgaria' },
    dialCode: '+359',
    iso2: 'BG',
  },
  {
    name: {
      'en-GB': 'Burkina Faso',
      'pt-PT': 'Burquina Faso',
      'it-IT': 'Burkina Faso',
    },
    dialCode: '+226',
    iso2: 'BF',
  },
  {
    name: { 'en-GB': 'Burundi', 'pt-PT': 'Burundi', 'it-IT': 'Burundi' },
    dialCode: '+257',
    iso2: 'BI',
  },
  {
    name: { 'en-GB': 'Cambodia', 'pt-PT': 'Camboja', 'it-IT': 'Cambogia' },
    dialCode: '+855',
    iso2: 'KH',
  },
  {
    name: {
      'en-GB': 'Cameroon',
      'pt-PT': 'República dos Camarões',
      'it-IT': 'Camerun',
    },
    dialCode: '+237',
    iso2: 'CM',
  },
  {
    name: { 'en-GB': 'Canada', 'pt-PT': 'Canadá', 'it-IT': 'Canada' },
    dialCode: '+1',
    iso2: 'CA',
  },
  {
    name: {
      'en-GB': 'Cape Verde',
      'pt-PT': 'Cabo Verde',
      'it-IT': 'Capo Verde',
    },
    dialCode: '+238',
    iso2: 'CV',
  },
  {
    name: {
      'en-GB': 'Cayman Islands',
      'pt-PT': 'Ilhas Caiman',
      'it-IT': 'Isole Cayman',
    },
    dialCode: '+ 345',
    iso2: 'KY',
  },
  {
    name: {
      'en-GB': 'Central African Republic',
      'pt-PT': 'República Centro-Africana',
      'it-IT': 'Repubblica Centrafricana',
    },
    dialCode: '+236',
    iso2: 'CF',
  },
  {
    name: { 'en-GB': 'Chad', 'pt-PT': 'Chade', 'it-IT': 'Ciad' },
    dialCode: '+235',
    iso2: 'TD',
  },
  {
    name: { 'en-GB': 'Chile', 'pt-PT': 'Chile', 'it-IT': 'Cile' },
    dialCode: '+56',
    iso2: 'CL',
  },
  {
    name: { 'en-GB': 'China', 'pt-PT': 'China', 'it-IT': 'Cina' },
    dialCode: '+86',
    iso2: 'CN',
  },
  {
    name: {
      'en-GB': 'Christmas Island',
      'pt-PT': 'Ilha Christmas',
      'it-IT': 'Isola Christmas',
    },
    dialCode: '+61',
    iso2: 'CX',
  },
  {
    name: { 'en-GB': 'Colombia', 'pt-PT': 'Colômbia', 'it-IT': 'Colombia' },
    dialCode: '+57',
    iso2: 'CO',
  },
  {
    name: { 'en-GB': 'Comoros', 'pt-PT': 'Comores', 'it-IT': 'Comore' },
    dialCode: '+269',
    iso2: 'KM',
  },
  {
    name: {
      'en-GB': 'Congo',
      'pt-PT': 'Congo - Brazzaville',
      'it-IT': 'Congo-Brazzaville',
    },
    dialCode: '+242',
    iso2: 'CG',
  },
  {
    name: {
      'en-GB': 'Cook Islands',
      'pt-PT': 'Ilhas Cook',
      'it-IT': 'Isole Cook',
    },
    dialCode: '+682',
    iso2: 'CK',
  },
  {
    name: {
      'en-GB': 'Costa Rica',
      'pt-PT': 'Costa Rica',
      'it-IT': 'Costa Rica',
    },
    dialCode: '+506',
    iso2: 'CR',
  },
  {
    name: { 'en-GB': 'Croatia', 'pt-PT': 'Croácia', 'it-IT': 'Croazia' },
    dialCode: '+385',
    iso2: 'HR',
  },
  {
    name: { 'en-GB': 'Cuba', 'pt-PT': 'Cuba', 'it-IT': 'Cuba' },
    dialCode: '+53',
    iso2: 'CU',
  },
  {
    name: { 'en-GB': 'Cyprus', 'pt-PT': 'Chipre', 'it-IT': 'Cipro' },
    dialCode: '+537',
    iso2: 'CY',
  },
  {
    name: {
      'en-GB': 'Czech Republic',
      'pt-PT': 'República Tcheca',
      'it-IT': 'Cechia',
    },
    dialCode: '+420',
    iso2: 'CZ',
  },
  {
    name: { 'en-GB': 'Denmark', 'pt-PT': 'Dinamarca', 'it-IT': 'Danimarca' },
    dialCode: '+45',
    iso2: 'DK',
  },
  {
    name: { 'en-GB': 'Djibouti', 'pt-PT': 'Djibuti', 'it-IT': 'Gibuti' },
    dialCode: '+253',
    iso2: 'DJ',
  },
  {
    name: { 'en-GB': 'Dominica', 'pt-PT': 'Dominica', 'it-IT': 'Dominica' },
    dialCode: '+1 767',
    iso2: 'DM',
  },
  {
    name: {
      'en-GB': 'Dominican Republic',
      'pt-PT': 'República Dominicana',
      'it-IT': 'Repubblica Dominicana',
    },
    dialCode: '+1 849',
    iso2: 'DO',
  },
  {
    name: { 'en-GB': 'Ecuador', 'pt-PT': 'Equador', 'it-IT': 'Ecuador' },
    dialCode: '+593',
    iso2: 'EC',
  },
  {
    name: { 'en-GB': 'Egypt', 'pt-PT': 'Egito', 'it-IT': 'Egitto' },
    dialCode: '+20',
    iso2: 'EG',
  },
  {
    name: {
      'en-GB': 'El Salvador',
      'pt-PT': 'El Salvador',
      'it-IT': 'El Salvador',
    },
    dialCode: '+503',
    iso2: 'SV',
  },
  {
    name: {
      'en-GB': 'Equatorial Guinea',
      'pt-PT': 'Guiné Equatorial',
      'it-IT': 'Guinea Equatoriale',
    },
    dialCode: '+240',
    iso2: 'GQ',
  },
  {
    name: { 'en-GB': 'Eritrea', 'pt-PT': 'Eritreia', 'it-IT': 'Eritrea' },
    dialCode: '+291',
    iso2: 'ER',
  },
  {
    name: { 'en-GB': 'Estonia', 'pt-PT': 'Estônia', 'it-IT': 'Estonia' },
    dialCode: '+372',
    iso2: 'EE',
  },
  {
    name: { 'en-GB': 'Ethiopia', 'pt-PT': 'Etiópia', 'it-IT': 'Etiopia' },
    dialCode: '+251',
    iso2: 'ET',
  },
  {
    name: {
      'en-GB': 'Faroe Islands',
      'pt-PT': 'Ilhas Faroe',
      'it-IT': 'Isole Fær Øer',
    },
    dialCode: '+298',
    iso2: 'FO',
  },
  {
    name: { 'en-GB': 'Fiji', 'pt-PT': 'Fiji', 'it-IT': 'Figi' },
    dialCode: '+679',
    iso2: 'FJ',
  },
  {
    name: { 'en-GB': 'Finland', 'pt-PT': 'Finlândia', 'it-IT': 'Finlandia' },
    dialCode: '+358',
    iso2: 'FI',
  },
  {
    name: { 'en-GB': 'France', 'pt-PT': 'França', 'it-IT': 'Francia' },
    dialCode: '+33',
    iso2: 'FR',
  },
  {
    name: {
      'en-GB': 'French Guiana',
      'pt-PT': 'Guiana Francesa',
      'it-IT': 'Guyana francese',
    },
    dialCode: '+594',
    iso2: 'GF',
  },
  {
    name: {
      'en-GB': 'French Polynesia',
      'pt-PT': 'Polinésia Francesa',
      'it-IT': 'Polinesia francese',
    },
    dialCode: '+689',
    iso2: 'PF',
  },
  {
    name: { 'en-GB': 'Gabon', 'pt-PT': 'Gabão', 'it-IT': 'Gabon' },
    dialCode: '+241',
    iso2: 'GA',
  },
  {
    name: { 'en-GB': 'Gambia', 'pt-PT': 'Gâmbia', 'it-IT': 'Gambia' },
    dialCode: '+220',
    iso2: 'GM',
  },
  {
    name: { 'en-GB': 'Georgia', 'pt-PT': 'Geórgia', 'it-IT': 'Georgia' },
    dialCode: '+995',
    iso2: 'GE',
  },
  {
    name: { 'en-GB': 'Germany', 'pt-PT': 'Alemanha', 'it-IT': 'Germania' },
    dialCode: '+49',
    iso2: 'DE',
  },
  {
    name: { 'en-GB': 'Ghana', 'pt-PT': 'Gana', 'it-IT': 'Ghana' },
    dialCode: '+233',
    iso2: 'GH',
  },
  {
    name: { 'en-GB': 'Gibraltar', 'pt-PT': 'Gibraltar', 'it-IT': 'Gibilterra' },
    dialCode: '+350',
    iso2: 'GI',
  },
  {
    name: { 'en-GB': 'Greece', 'pt-PT': 'Grécia', 'it-IT': 'Grecia' },
    dialCode: '+30',
    iso2: 'GR',
  },
  {
    name: {
      'en-GB': 'Greenland',
      'pt-PT': 'Groenlândia',
      'it-IT': 'Groenlandia',
    },
    dialCode: '+299',
    iso2: 'GL',
  },
  {
    name: { 'en-GB': 'Grenada', 'pt-PT': 'Granada', 'it-IT': 'Grenada' },
    dialCode: '+1 473',
    iso2: 'GD',
  },
  {
    name: { 'en-GB': 'Guadeloupe', 'pt-PT': 'Guadalupe', 'it-IT': 'Guadalupa' },
    dialCode: '+590',
    iso2: 'GP',
  },
  {
    name: { 'en-GB': 'Guam', 'pt-PT': 'Guam', 'it-IT': 'Guam' },
    dialCode: '+1 671',
    iso2: 'GU',
  },
  {
    name: { 'en-GB': 'Guatemala', 'pt-PT': 'Guatemala', 'it-IT': 'Guatemala' },
    dialCode: '+502',
    iso2: 'GT',
  },
  {
    name: { 'en-GB': 'Guinea', 'pt-PT': 'Guiné', 'it-IT': 'Guinea' },
    dialCode: '+224',
    iso2: 'GN',
  },
  {
    name: {
      'en-GB': 'Guinea-Bissau',
      'pt-PT': 'Guiné Bissau',
      'it-IT': 'Guinea-Bissau',
    },
    dialCode: '+245',
    iso2: 'GW',
  },
  {
    name: { 'en-GB': 'Guyana', 'pt-PT': 'Guiana', 'it-IT': 'Guyana' },
    dialCode: '+595',
    iso2: 'GY',
  },
  {
    name: { 'en-GB': 'Haiti', 'pt-PT': 'Haiti', 'it-IT': 'Haiti' },
    dialCode: '+509',
    iso2: 'HT',
  },
  {
    name: { 'en-GB': 'Honduras', 'pt-PT': 'Honduras', 'it-IT': 'Honduras' },
    dialCode: '+504',
    iso2: 'HN',
  },
  {
    name: { 'en-GB': 'Hungary', 'pt-PT': 'Hungria', 'it-IT': 'Ungheria' },
    dialCode: '+36',
    iso2: 'HU',
  },
  {
    name: { 'en-GB': 'Iceland', 'pt-PT': 'Islândia', 'it-IT': 'Islanda' },
    dialCode: '+354',
    iso2: 'IS',
  },
  {
    name: { 'en-GB': 'India', 'pt-PT': 'Índia', 'it-IT': 'India' },
    dialCode: '+91',
    iso2: 'IN',
  },
  {
    name: { 'en-GB': 'Indonesia', 'pt-PT': 'Indonésia', 'it-IT': 'Indonesia' },
    dialCode: '+62',
    iso2: 'ID',
  },
  {
    name: { 'en-GB': 'Iraq', 'pt-PT': 'Iraque', 'it-IT': 'Iraq' },
    dialCode: '+964',
    iso2: 'IQ',
  },
  {
    name: { 'en-GB': 'Ireland', 'pt-PT': 'Irlanda', 'it-IT': 'Irlanda' },
    dialCode: '+353',
    iso2: 'IE',
  },
  {
    name: { 'en-GB': 'Israel', 'pt-PT': 'Israel', 'it-IT': 'Israele' },
    dialCode: '+972',
    iso2: 'IL',
  },
  {
    name: { 'en-GB': 'Italy', 'pt-PT': 'Itália', 'it-IT': 'Italia' },
    dialCode: '+39',
    iso2: 'IT',
  },
  {
    name: { 'en-GB': 'Jamaica', 'pt-PT': 'Jamaica', 'it-IT': 'Giamaica' },
    dialCode: '+1 876',
    iso2: 'JM',
  },
  {
    name: { 'en-GB': 'Japan', 'pt-PT': 'Japão', 'it-IT': 'Giappone' },
    dialCode: '+81',
    iso2: 'JP',
  },
  {
    name: { 'en-GB': 'Jordan', 'pt-PT': 'Jordânia', 'it-IT': 'Giordania' },
    dialCode: '+962',
    iso2: 'JO',
  },
  {
    name: {
      'en-GB': 'Kazakhstan',
      'pt-PT': 'Cazaquistão',
      'it-IT': 'Kazakistan',
    },
    dialCode: '+7 7',
    iso2: 'KZ',
  },
  {
    name: { 'en-GB': 'Kenya', 'pt-PT': 'Quênia', 'it-IT': 'Kenya' },
    dialCode: '+254',
    iso2: 'KE',
  },
  {
    name: { 'en-GB': 'Kiribati', 'pt-PT': 'Quiribati', 'it-IT': 'Kiribati' },
    dialCode: '+686',
    iso2: 'KI',
  },
  {
    name: { 'en-GB': 'Kuwait', 'pt-PT': 'Kuwait', 'it-IT': 'Kuwait' },
    dialCode: '+965',
    iso2: 'KW',
  },
  {
    name: {
      'en-GB': 'Kyrgyzstan',
      'pt-PT': 'Quirguistão',
      'it-IT': 'Kirghizistan',
    },
    dialCode: '+996',
    iso2: 'KG',
  },
  {
    name: { 'en-GB': 'Latvia', 'pt-PT': 'Letônia', 'it-IT': 'Lettonia' },
    dialCode: '+371',
    iso2: 'LV',
  },
  {
    name: { 'en-GB': 'Lebanon', 'pt-PT': 'Líbano', 'it-IT': 'Libano' },
    dialCode: '+961',
    iso2: 'LB',
  },
  {
    name: { 'en-GB': 'Lesotho', 'pt-PT': 'Lesoto', 'it-IT': 'Lesotho' },
    dialCode: '+266',
    iso2: 'LS',
  },
  {
    name: { 'en-GB': 'Liberia', 'pt-PT': 'Libéria', 'it-IT': 'Liberia' },
    dialCode: '+231',
    iso2: 'LR',
  },
  {
    name: {
      'en-GB': 'Liechtenstein',
      'pt-PT': 'Liechtenstein',
      'it-IT': 'Liechtenstein',
    },
    dialCode: '+423',
    iso2: 'LI',
  },
  {
    name: { 'en-GB': 'Lithuania', 'pt-PT': 'Lituânia', 'it-IT': 'Lituania' },
    dialCode: '+370',
    iso2: 'LT',
  },
  {
    name: {
      'en-GB': 'Luxembourg',
      'pt-PT': 'Luxemburgo',
      'it-IT': 'Lussemburgo',
    },
    dialCode: '+352',
    iso2: 'LU',
  },
  {
    name: {
      'en-GB': 'Madagascar',
      'pt-PT': 'Madagascar',
      'it-IT': 'Madagascar',
    },
    dialCode: '+261',
    iso2: 'MG',
  },
  {
    name: { 'en-GB': 'Malawi', 'pt-PT': 'Malawi', 'it-IT': 'Malawi' },
    dialCode: '+265',
    iso2: 'MW',
  },
  {
    name: { 'en-GB': 'Malaysia', 'pt-PT': 'Malásia', 'it-IT': 'Malaysia' },
    dialCode: '+60',
    iso2: 'MY',
  },
  {
    name: { 'en-GB': 'Maldives', 'pt-PT': 'Maldivas', 'it-IT': 'Maldive' },
    dialCode: '+960',
    iso2: 'MV',
  },
  {
    name: { 'en-GB': 'Mali', 'pt-PT': 'Mali', 'it-IT': 'Mali' },
    dialCode: '+223',
    iso2: 'ML',
  },
  {
    name: { 'en-GB': 'Malta', 'pt-PT': 'Malta', 'it-IT': 'Malta' },
    dialCode: '+356',
    iso2: 'MT',
  },
  {
    name: {
      'en-GB': 'Marshall Islands',
      'pt-PT': 'Ilhas Marshall',
      'it-IT': 'Isole Marshall',
    },
    dialCode: '+692',
    iso2: 'MH',
  },
  {
    name: { 'en-GB': 'Martinique', 'pt-PT': 'Martinica', 'it-IT': 'Martinica' },
    dialCode: '+596',
    iso2: 'MQ',
  },
  {
    name: {
      'en-GB': 'Mauritania',
      'pt-PT': 'Mauritânia',
      'it-IT': 'Mauritania',
    },
    dialCode: '+222',
    iso2: 'MR',
  },
  {
    name: { 'en-GB': 'Mauritius', 'pt-PT': 'Maurício', 'it-IT': 'Mauritius' },
    dialCode: '+230',
    iso2: 'MU',
  },
  {
    name: { 'en-GB': 'Mayotte', 'pt-PT': 'Mayotte', 'it-IT': 'Mayotte' },
    dialCode: '+262',
    iso2: 'YT',
  },
  {
    name: { 'en-GB': 'Mexico', 'pt-PT': 'México', 'it-IT': 'Messico' },
    dialCode: '+52',
    iso2: 'MX',
  },
  {
    name: { 'en-GB': 'Monaco', 'pt-PT': 'Mônaco', 'it-IT': 'Monaco' },
    dialCode: '+377',
    iso2: 'MC',
  },
  {
    name: { 'en-GB': 'Mongolia', 'pt-PT': 'Mongólia', 'it-IT': 'Mongolia' },
    dialCode: '+976',
    iso2: 'MN',
  },
  {
    name: {
      'en-GB': 'Montenegro',
      'pt-PT': 'Montenegro',
      'it-IT': 'Montenegro',
    },
    dialCode: '+382',
    iso2: 'ME',
  },
  {
    name: {
      'en-GB': 'Montserrat',
      'pt-PT': 'Montserrat',
      'it-IT': 'Montserrat',
    },
    dialCode: '+1664',
    iso2: 'MS',
  },
  {
    name: { 'en-GB': 'Morocco', 'pt-PT': 'Marrocos', 'it-IT': 'Marocco' },
    dialCode: '+212',
    iso2: 'MA',
  },
  {
    name: {
      'en-GB': 'Myanmar',
      'pt-PT': 'Mianmar (Birmânia)',
      'it-IT': 'Myanmar (Birmania)',
    },
    dialCode: '+95',
    iso2: 'MM',
  },
  {
    name: { 'en-GB': 'Namibia', 'pt-PT': 'Namíbia', 'it-IT': 'Namibia' },
    dialCode: '+264',
    iso2: 'NA',
  },
  {
    name: { 'en-GB': 'Nauru', 'pt-PT': 'Nauru', 'it-IT': 'Nauru' },
    dialCode: '+674',
    iso2: 'NR',
  },
  {
    name: { 'en-GB': 'Nepal', 'pt-PT': 'Nepal', 'it-IT': 'Nepal' },
    dialCode: '+977',
    iso2: 'NP',
  },
  {
    name: {
      'en-GB': 'Netherlands',
      'pt-PT': 'Holanda',
      'it-IT': 'Paesi Bassi',
    },
    dialCode: '+31',
    iso2: 'NL',
  },
  {
    name: { 'en-GB': 'Netherlands Antilles', 'pt-PT': 'Netherlands Antilles' },
    dialCode: '+599',
    iso2: 'AN',
  },
  {
    name: {
      'en-GB': 'New Caledonia',
      'pt-PT': 'Nova Caledônia',
      'it-IT': 'Nuova Caledonia',
    },
    dialCode: '+687',
    iso2: 'NC',
  },
  {
    name: {
      'en-GB': 'New Zealand',
      'pt-PT': 'Nova Zelândia',
      'it-IT': 'Nuova Zelanda',
    },
    dialCode: '+64',
    iso2: 'NZ',
  },
  {
    name: { 'en-GB': 'Nicaragua', 'pt-PT': 'Nicarágua', 'it-IT': 'Nicaragua' },
    dialCode: '+505',
    iso2: 'NI',
  },
  {
    name: { 'en-GB': 'Niger', 'pt-PT': 'Níger', 'it-IT': 'Niger' },
    dialCode: '+227',
    iso2: 'NE',
  },
  {
    name: { 'en-GB': 'Nigeria', 'pt-PT': 'Nigéria', 'it-IT': 'Nigeria' },
    dialCode: '+234',
    iso2: 'NG',
  },
  {
    name: { 'en-GB': 'Niue', 'pt-PT': 'Niue', 'it-IT': 'Niue' },
    dialCode: '+683',
    iso2: 'NU',
  },
  {
    name: {
      'en-GB': 'Norfolk Island',
      'pt-PT': 'Ilha Norfolk',
      'it-IT': 'Isola Norfolk',
    },
    dialCode: '+672',
    iso2: 'NF',
  },
  {
    name: {
      'en-GB': 'Northern Mariana Islands',
      'pt-PT': 'Ilhas Marianas do Norte',
      'it-IT': 'Isole Marianne settentrionali',
    },
    dialCode: '+1 670',
    iso2: 'MP',
  },
  {
    name: { 'en-GB': 'Norway', 'pt-PT': 'Noruega', 'it-IT': 'Norvegia' },
    dialCode: '+47',
    iso2: 'NO',
  },
  {
    name: { 'en-GB': 'Oman', 'pt-PT': 'Omã', 'it-IT': 'Oman' },
    dialCode: '+968',
    iso2: 'OM',
  },
  {
    name: { 'en-GB': 'Pakistan', 'pt-PT': 'Paquistão', 'it-IT': 'Pakistan' },
    dialCode: '+92',
    iso2: 'PK',
  },
  {
    name: { 'en-GB': 'Palau', 'pt-PT': 'Palau', 'it-IT': 'Palau' },
    dialCode: '+680',
    iso2: 'PW',
  },
  {
    name: { 'en-GB': 'Panama', 'pt-PT': 'Panamá', 'it-IT': 'Panamá' },
    dialCode: '+507',
    iso2: 'PA',
  },
  {
    name: {
      'en-GB': 'Papua New Guinea',
      'pt-PT': 'Papua-Nova Guiné',
      'it-IT': 'Papua Nuova Guinea',
    },
    dialCode: '+675',
    iso2: 'PG',
  },
  {
    name: { 'en-GB': 'Paraguay', 'pt-PT': 'Paraguai', 'it-IT': 'Paraguay' },
    dialCode: '+595',
    iso2: 'PY',
  },
  {
    name: { 'en-GB': 'Peru', 'pt-PT': 'Peru', 'it-IT': 'Perù' },
    dialCode: '+51',
    iso2: 'PE',
  },
  {
    name: {
      'en-GB': 'Philippines',
      'pt-PT': 'Filipinas',
      'it-IT': 'Filippine',
    },
    dialCode: '+63',
    iso2: 'PH',
  },
  {
    name: { 'en-GB': 'Poland', 'pt-PT': 'Polônia', 'it-IT': 'Polonia' },
    dialCode: '+48',
    iso2: 'PL',
  },
  {
    name: { 'en-GB': 'Portugal', 'pt-PT': 'Portugal', 'it-IT': 'Portogallo' },
    dialCode: '+351',
    iso2: 'PT',
  },
  {
    name: {
      'en-GB': 'Puerto Rico',
      'pt-PT': 'Porto Rico',
      'it-IT': 'Portorico',
    },
    dialCode: '+1 939',
    iso2: 'PR',
  },
  {
    name: { 'en-GB': 'Qatar', 'pt-PT': 'Catar', 'it-IT': 'Qatar' },
    dialCode: '+974',
    iso2: 'QA',
  },
  {
    name: { 'en-GB': 'Romania', 'pt-PT': 'Romênia', 'it-IT': 'Romania' },
    dialCode: '+40',
    iso2: 'RO',
  },
  {
    name: { 'en-GB': 'Rwanda', 'pt-PT': 'Ruanda', 'it-IT': 'Ruanda' },
    dialCode: '+250',
    iso2: 'RW',
  },
  {
    name: { 'en-GB': 'Samoa', 'pt-PT': 'Samoa', 'it-IT': 'Samoa' },
    dialCode: '+685',
    iso2: 'WS',
  },
  {
    name: {
      'en-GB': 'San Marino',
      'pt-PT': 'San Marino',
      'it-IT': 'San Marino',
    },
    dialCode: '+378',
    iso2: 'SM',
  },
  {
    name: {
      'en-GB': 'Saudi Arabia',
      'pt-PT': 'Arábia Saudita',
      'it-IT': 'Arabia Saudita',
    },
    dialCode: '+966',
    iso2: 'SA',
  },
  {
    name: { 'en-GB': 'Senegal', 'pt-PT': 'Senegal', 'it-IT': 'Senegal' },
    dialCode: '+221',
    iso2: 'SN',
  },
  {
    name: { 'en-GB': 'Serbia', 'pt-PT': 'Sérvia', 'it-IT': 'Serbia' },
    dialCode: '+381',
    iso2: 'RS',
  },
  {
    name: {
      'en-GB': 'Seychelles',
      'pt-PT': 'Seychelles',
      'it-IT': 'Seychelles',
    },
    dialCode: '+248',
    iso2: 'SC',
  },
  {
    name: {
      'en-GB': 'Sierra Leone',
      'pt-PT': 'Serra Leoa',
      'it-IT': 'Sierra Leone',
    },
    dialCode: '+232',
    iso2: 'SL',
  },
  {
    name: { 'en-GB': 'Singapore', 'pt-PT': 'Singapura', 'it-IT': 'Singapore' },
    dialCode: '+65',
    iso2: 'SG',
  },
  {
    name: { 'en-GB': 'Slovakia', 'pt-PT': 'Eslováquia', 'it-IT': 'Slovacchia' },
    dialCode: '+421',
    iso2: 'SK',
  },
  {
    name: { 'en-GB': 'Slovenia', 'pt-PT': 'Eslovênia', 'it-IT': 'Slovenia' },
    dialCode: '+386',
    iso2: 'SI',
  },
  {
    name: {
      'en-GB': 'Solomon Islands',
      'pt-PT': 'Ilhas Salomão',
      'it-IT': 'Isole Salomone',
    },
    dialCode: '+677',
    iso2: 'SB',
  },
  {
    name: {
      'en-GB': 'South Africa',
      'pt-PT': 'África do Sul',
      'it-IT': 'Sudafrica',
    },
    dialCode: '+27',
    iso2: 'ZA',
  },
  {
    name: {
      'en-GB': 'South Georgia and the South Sandwich Islands',
      'pt-PT': 'Geórgia do Sul e Ilhas Sandwich do Sul',
      'it-IT': 'Georgia del Sud e Sandwich australi',
    },
    dialCode: '+500',
    iso2: 'GS',
  },
  {
    name: { 'en-GB': 'Spain', 'pt-PT': 'Espanha', 'it-IT': 'Spagna' },
    dialCode: '+34',
    iso2: 'ES',
  },
  {
    name: { 'en-GB': 'Sri Lanka', 'pt-PT': 'Sri Lanka', 'it-IT': 'Sri Lanka' },
    dialCode: '+94',
    iso2: 'LK',
  },
  {
    name: { 'en-GB': 'Sudan', 'pt-PT': 'Sudão', 'it-IT': 'Sudan' },
    dialCode: '+249',
    iso2: 'SD',
  },
  {
    name: { 'en-GB': 'Suriname', 'pt-PT': 'Suriname', 'it-IT': 'Suriname' },
    dialCode: '+597',
    iso2: 'SR',
  },
  {
    name: { 'en-GB': 'Eswatini', 'pt-PT': 'Suazilândia', 'it-IT': 'Swaziland' },
    dialCode: '+268',
    iso2: 'SZ',
  },
  {
    name: { 'en-GB': 'Sweden', 'pt-PT': 'Suécia', 'it-IT': 'Svezia' },
    dialCode: '+46',
    iso2: 'SE',
  },
  {
    name: { 'en-GB': 'Switzerland', 'pt-PT': 'Suíça', 'it-IT': 'Svizzera' },
    dialCode: '+41',
    iso2: 'CH',
  },
  {
    name: {
      'en-GB': 'Tajikistan',
      'pt-PT': 'Tajiquistão',
      'it-IT': 'Tagikistan',
    },
    dialCode: '+992',
    iso2: 'TJ',
  },
  {
    name: { 'en-GB': 'Thailand', 'pt-PT': 'Tailândia', 'it-IT': 'Thailandia' },
    dialCode: '+66',
    iso2: 'TH',
  },
  {
    name: { 'en-GB': 'Togo', 'pt-PT': 'Togo', 'it-IT': 'Togo' },
    dialCode: '+228',
    iso2: 'TG',
  },
  {
    name: { 'en-GB': 'Tokelau', 'pt-PT': 'Tokelau', 'it-IT': 'Tokelau' },
    dialCode: '+690',
    iso2: 'TK',
  },
  {
    name: { 'en-GB': 'Tonga', 'pt-PT': 'Tonga', 'it-IT': 'Tonga' },
    dialCode: '+676',
    iso2: 'TO',
  },
  {
    name: {
      'en-GB': 'Trinidad and Tobago',
      'pt-PT': 'Trinidad e Tobago',
      'it-IT': 'Trinidad e Tobago',
    },
    dialCode: '+1 868',
    iso2: 'TT',
  },
  {
    name: { 'en-GB': 'Tunisia', 'pt-PT': 'Tunísia', 'it-IT': 'Tunisia' },
    dialCode: '+216',
    iso2: 'TN',
  },
  {
    name: { 'en-GB': 'Turkey', 'pt-PT': 'Turquia', 'it-IT': 'Turchia' },
    dialCode: '+90',
    iso2: 'TR',
  },
  {
    name: {
      'en-GB': 'Turkmenistan',
      'pt-PT': 'Turcomenistão',
      'it-IT': 'Turkmenistan',
    },
    dialCode: '+993',
    iso2: 'TM',
  },
  {
    name: {
      'en-GB': 'Turks and Caicos Islands',
      'pt-PT': 'Ilhas Turks e Caicos',
      'it-IT': 'Isole Turks e Caicos',
    },
    dialCode: '+1 649',
    iso2: 'TC',
  },
  {
    name: { 'en-GB': 'Tuvalu', 'pt-PT': 'Tuvalu', 'it-IT': 'Tuvalu' },
    dialCode: '+688',
    iso2: 'TV',
  },
  {
    name: { 'en-GB': 'Uganda', 'pt-PT': 'Uganda', 'it-IT': 'Uganda' },
    dialCode: '+256',
    iso2: 'UG',
  },
  {
    name: { 'en-GB': 'Ukraine', 'pt-PT': 'Ucrânia', 'it-IT': 'Ucraina' },
    dialCode: '+380',
    iso2: 'UA',
  },
  {
    name: {
      'en-GB': 'United Arab Emirates',
      'pt-PT': 'Emirados Árabes Unidos',
      'it-IT': 'Emirati Arabi Uniti',
    },
    dialCode: '+971',
    iso2: 'AE',
  },
  {
    name: {
      'en-GB': 'United Kingdom',
      'pt-PT': 'Reino Unido',
      'it-IT': 'Regno Unito',
    },
    dialCode: '+44',
    iso2: 'GB',
  },
  {
    name: {
      'en-GB': 'United States of America',
      'pt-PT': 'Estados Unidos da América',
      'it-IT': 'Stati Uniti',
    },
    dialCode: '+1',
    iso2: 'US',
  },
  {
    name: { 'en-GB': 'Uruguay', 'pt-PT': 'Uruguai', 'it-IT': 'Uruguay' },
    dialCode: '+598',
    iso2: 'UY',
  },
  {
    name: {
      'en-GB': 'Uzbekistan',
      'pt-PT': 'Uzbequistão',
      'it-IT': 'Uzbekistan',
    },
    dialCode: '+998',
    iso2: 'UZ',
  },
  {
    name: { 'en-GB': 'Vanuatu', 'pt-PT': 'Vanuatu', 'it-IT': 'Vanuatu' },
    dialCode: '+678',
    iso2: 'VU',
  },
  {
    name: {
      'en-GB': 'Wallis and Futuna',
      'pt-PT': 'Wallis e Futuna',
      'it-IT': 'Wallis e Futuna',
    },
    dialCode: '+681',
    iso2: 'WF',
  },
  {
    name: { 'en-GB': 'Yemen', 'pt-PT': 'Iêmen', 'it-IT': 'Yemen' },
    dialCode: '+967',
    iso2: 'YE',
  },
  {
    name: { 'en-GB': 'Zambia', 'pt-PT': 'Zâmbia', 'it-IT': 'Zambia' },
    dialCode: '+260',
    iso2: 'ZM',
  },
  {
    name: { 'en-GB': 'Zimbabwe', 'pt-PT': 'Zimbábue', 'it-IT': 'Zimbabwe' },
    dialCode: '+263',
    iso2: 'ZW',
  },
  {
    name: {
      'en-GB': 'Åland Islands',
      'pt-PT': 'Ilhas Åland',
      'it-IT': 'Isole Åland',
    },
    dialCode: '',
    iso2: 'AX',
  },
  {
    name: { 'en-GB': 'Antarctica', 'pt-PT': 'Antártida', 'it-IT': 'Antartide' },
    dialCode: null,
    iso2: 'AQ',
  },
  {
    name: { 'en-GB': 'Bolivia', 'pt-PT': 'Bolívia', 'it-IT': 'Bolivia' },
    dialCode: '+591',
    iso2: 'BO',
  },
  {
    name: {
      'en-GB': 'Brunei Darussalam',
      'pt-PT': 'Brunei',
      'it-IT': 'Brunei',
    },
    dialCode: '+673',
    iso2: 'BN',
  },
  {
    name: {
      'en-GB': 'Cocos (Keeling) Islands',
      'pt-PT': 'Ilhas Cocos (Keeling)',
      'it-IT': 'Isole Cocos (Keeling)',
    },
    dialCode: '+61',
    iso2: 'CC',
  },
  {
    name: {
      'en-GB': 'Congo, the Democratic Republic of the',
      'pt-PT': 'Congo - Kinshasa',
      'it-IT': 'Congo - Kinshasa',
    },
    dialCode: '+243',
    iso2: 'CD',
  },
  {
    name: {
      'en-GB': "Cote D'Ivoire",
      'pt-PT': 'Costa do Marfim',
      'it-IT': 'Costa d’Avorio',
    },
    dialCode: '+225',
    iso2: 'CI',
  },
  {
    name: {
      'en-GB': 'Falkland Islands (Malvinas)',
      'pt-PT': 'Ilhas Malvinas',
      'it-IT': 'Isole Falkland',
    },
    dialCode: '+500',
    iso2: 'FK',
  },
  {
    name: { 'en-GB': 'Guernsey', 'pt-PT': 'Guernsey', 'it-IT': 'Guernsey' },
    dialCode: '+44',
    iso2: 'GG',
  },
  {
    name: {
      'en-GB': 'Holy See (Vatican City State)',
      'pt-PT': 'Cidade do Vaticano',
      'it-IT': 'Città del Vaticano',
    },
    dialCode: '+379',
    iso2: 'VA',
  },
  {
    name: {
      'en-GB': 'Hong Kong',
      'pt-PT': 'Hong Kong, RAE da China',
      'it-IT': 'RAS di Hong Kong',
    },
    dialCode: '+852',
    iso2: 'HK',
  },
  {
    name: {
      'en-GB': 'Iran, Islamic Republic of',
      'pt-PT': 'Irã',
      'it-IT': 'Iran',
    },
    dialCode: '+98',
    iso2: 'IR',
  },
  {
    name: {
      'en-GB': 'Isle of Man',
      'pt-PT': 'Ilha de Man',
      'it-IT': 'Isola di Man',
    },
    dialCode: '+44',
    iso2: 'IM',
  },
  {
    name: { 'en-GB': 'Jersey', 'pt-PT': 'Jersey', 'it-IT': 'Jersey' },
    dialCode: '+44',
    iso2: 'JE',
  },
  {
    name: {
      'en-GB': 'North Korea',
      'pt-PT': 'Coreia do Norte',
      'it-IT': 'Corea del Nord',
    },
    dialCode: '+850',
    iso2: 'KP',
  },
  {
    name: {
      'en-GB': 'South Korea',
      'pt-PT': 'Coreia do Sul',
      'it-IT': 'Corea del Sud',
    },
    dialCode: '+82',
    iso2: 'KR',
  },
  {
    name: {
      'en-GB': "Lao People's Democratic Republic",
      'pt-PT': 'Laos',
      'it-IT': 'Laos',
    },
    dialCode: '+856',
    iso2: 'LA',
  },
  {
    name: { 'en-GB': 'Libya', 'pt-PT': 'Líbia', 'it-IT': 'Libia' },
    dialCode: '+218',
    iso2: 'LY',
  },
  {
    name: {
      'en-GB': 'Macao',
      'pt-PT': 'Macau, RAE da China',
      'it-IT': 'RAS di Macao',
    },
    dialCode: '+853',
    iso2: 'MO',
  },
  {
    name: {
      'en-GB': 'North Macedonia, Republic of',
      'pt-PT': 'Macedônia do Norte',
      'it-IT': 'Macedonia del Nord',
    },
    dialCode: '+389',
    iso2: 'MK',
  },
  {
    name: {
      'en-GB': 'Micronesia, Federated States of',
      'pt-PT': 'Micronésia',
      'it-IT': 'Micronesia',
    },
    dialCode: '+691',
    iso2: 'FM',
  },
  {
    name: {
      'en-GB': 'Moldova, Republic of',
      'pt-PT': 'Moldávia',
      'it-IT': 'Moldavia',
    },
    dialCode: '+373',
    iso2: 'MD',
  },
  {
    name: {
      'en-GB': 'Mozambique',
      'pt-PT': 'Moçambique',
      'it-IT': 'Mozambico',
    },
    dialCode: '+258',
    iso2: 'MZ',
  },
  {
    name: {
      'en-GB': 'Palestinian Territory, Occupied',
      'pt-PT': 'Territórios palestinos',
      'it-IT': 'Territori palestinesi',
    },
    dialCode: '+970',
    iso2: 'PS',
  },
  {
    name: {
      'en-GB': 'Pitcairn',
      'pt-PT': 'Ilhas Pitcairn',
      'it-IT': 'Isole Pitcairn',
    },
    dialCode: '+872',
    iso2: 'PN',
  },
  {
    name: { 'en-GB': 'Reunion', 'pt-PT': 'Reunião', 'it-IT': 'Riunione' },
    dialCode: '+262',
    iso2: 'RE',
  },
  {
    name: { 'en-GB': 'Russia', 'pt-PT': 'Rússia', 'it-IT': 'Russia' },
    dialCode: '+7',
    iso2: 'RU',
  },
  {
    name: {
      'en-GB': 'Saint Barthélemy',
      'pt-PT': 'São Bartolomeu',
      'it-IT': 'Saint-Barthélemy',
    },
    dialCode: '+590',
    iso2: 'BL',
  },
  {
    name: {
      'en-GB': 'Saint Helena',
      'pt-PT': 'Santa Helena',
      'it-IT': 'Sant’Elena',
    },
    dialCode: '+290',
    iso2: 'SH',
  },
  {
    name: {
      'en-GB': 'Saint Kitts and Nevis',
      'pt-PT': 'São Cristóvão e Nevis',
      'it-IT': 'Saint Kitts e Nevis',
    },
    dialCode: '+1 869',
    iso2: 'KN',
  },
  {
    name: {
      'en-GB': 'Saint Lucia',
      'pt-PT': 'Santa Lúcia',
      'it-IT': 'Saint Lucia',
    },
    dialCode: '+1 758',
    iso2: 'LC',
  },
  {
    name: {
      'en-GB': 'Saint Martin (French part)',
      'pt-PT': 'São Martinho',
      'it-IT': 'Saint Martin',
    },
    dialCode: '+590',
    iso2: 'MF',
  },
  {
    name: {
      'en-GB': 'Saint Pierre and Miquelon',
      'pt-PT': 'Saint Pierre e Miquelon',
      'it-IT': 'Saint-Pierre e Miquelon',
    },
    dialCode: '+508',
    iso2: 'PM',
  },
  {
    name: {
      'en-GB': 'Saint Vincent and the Grenadines',
      'pt-PT': 'São Vicente e Granadinas',
      'it-IT': 'Saint Vincent e Grenadine',
    },
    dialCode: '+1 784',
    iso2: 'VC',
  },
  {
    name: {
      'en-GB': 'Sao Tome and Principe',
      'pt-PT': 'São Tomé e Príncipe',
      'it-IT': 'São Tomé e Príncipe',
    },
    dialCode: '+239',
    iso2: 'ST',
  },
  {
    name: { 'en-GB': 'Somalia', 'pt-PT': 'Somália', 'it-IT': 'Somalia' },
    dialCode: '+252',
    iso2: 'SO',
  },
  {
    name: {
      'en-GB': 'Svalbard and Jan Mayen',
      'pt-PT': 'Svalbard e Jan Mayen',
      'it-IT': 'Svalbard e Jan Mayen',
    },
    dialCode: '+47',
    iso2: 'SJ',
  },
  {
    name: {
      'en-GB': 'Syrian Arab Republic',
      'pt-PT': 'Síria',
      'it-IT': 'Siria',
    },
    dialCode: '+963',
    iso2: 'SY',
  },
  {
    name: { 'en-GB': 'Taiwan', 'pt-PT': 'Taiwan', 'it-IT': 'Taiwan' },
    dialCode: '+886',
    iso2: 'TW',
  },
  {
    name: {
      'en-GB': 'Tanzania, United Republic of',
      'pt-PT': 'Tanzânia',
      'it-IT': 'Tanzania',
    },
    dialCode: '+255',
    iso2: 'TZ',
  },
  {
    name: {
      'en-GB': 'Timor-Leste',
      'pt-PT': 'Timor-Leste',
      'it-IT': 'Timor Est',
    },
    dialCode: '+670',
    iso2: 'TL',
  },
  {
    name: { 'en-GB': 'Venezuela', 'pt-PT': 'Venezuela', 'it-IT': 'Venezuela' },
    dialCode: '+58',
    iso2: 'VE',
  },
  {
    name: { 'en-GB': 'Vietnam', 'pt-PT': 'Vietnã', 'it-IT': 'Vietnam' },
    dialCode: '+84',
    iso2: 'VN',
  },
  {
    name: {
      'en-GB': 'Virgin Islands, British',
      'pt-PT': 'Ilhas Virgens Britânicas',
      'it-IT': 'Isole Vergini Britanniche',
    },
    dialCode: '+1 284',
    iso2: 'VG',
  },
  {
    name: {
      'en-GB': 'Virgin Islands, U.S.',
      'pt-PT': 'Ilhas Virgens dos EUA',
      'it-IT': 'Isole Vergini Americane',
    },
    dialCode: '+1 340',
    iso2: 'VI',
  },
];
