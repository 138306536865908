import { ReactNode } from 'react';
import { Button, LogoCoverflex } from '@coverflex-tech/hypernova';
import { ROUTES } from 'constants/routes';
import { i18n } from 'i18n';
import css from './ErrorPage.module.css';

export const ErrorPage = ({
  redirectTo,
  reloadLabel = String(i18n.t('general.errors.generic_error.reload')),
  goHomeLabel = String(i18n.t('general.errors.generic_error.go_home')),
  onClick,
  title = String(i18n.t('general.errors.generic_error.title')),
  description = String(i18n.t('general.errors.generic_error.description')),
  errorCode,
  hideActions,
}: TErrorPageProps) => {
  return (
    <div className={css.host}>
      <div className={css.header}>
        <LogoCoverflex />
      </div>
      <div className={css.content}>
        {Boolean(errorCode) && <div className={css.errorCode}>{errorCode}</div>}
        {title && <div className={css.title}>{title}</div>}
        <div className={css.description}>{description}</div>
        {!hideActions && (
          <div className={css.actions}>
            <Button
              variant="secondary"
              onClick={() => window.location.assign(ROUTES.ROOT)}
            >
              {goHomeLabel}
            </Button>
            <a
              href={redirectTo}
              onClick={(evt) => {
                if (!redirectTo) {
                  evt.preventDefault();

                  if (onClick) {
                    onClick();
                  }
                  window.location.reload();
                }
              }}
            >
              <Button variant="primary" presentational>
                {reloadLabel}
              </Button>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export type TErrorPageProps = {
  onClick?: () => void;
  reloadLabel?: ReactNode;
  goHomeLabel?: ReactNode;
  errorCode?: ReactNode;
  title?: ReactNode;
  description?: ReactNode;
  redirectTo?: string;
  hideActions?: boolean;
};
