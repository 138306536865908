import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { GenericModal, Heading, Text } from '@coverflex-tech/hypernova';
import { ROUTES } from 'constants/routes';
import { CreatePasswordForm } from 'containers/Authentication/components/CreatePasswordForm/CreatePasswordForm';
import { useAuth } from 'contexts/AuthProvider';
import usePageViewTracking from 'hooks/usePageViewTracking';
import AnalyticsService from 'services/analytics';
import authService from 'services/auth';

interface IProps {
  token: string;
}

export const ConfirmReset = ({ token }: IProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { logout } = useAuth();
  usePageViewTracking('reset_password_page');

  const handleSubmit = useCallback(
    async (form: { password: string }) => {
      try {
        setSubmitting(true);
        AnalyticsService.trackEvent('reset_password_button_clicked');
        await authService.confirmNewPassword(token, form.password);
        logout();
        history.push(ROUTES.SIGNIN);
      } catch {
        setShowModal(true);
        setSubmitting(false);
      }
    },
    [token, history, logout]
  );

  return (
    <>
      <Heading level={3}>{t('reset_password.confirm.title')}</Heading>
      <CreatePasswordForm
        token={token}
        buttonLabel={t('reset_password.confirm.button')}
        submitting={submitting}
        onSubmit={handleSubmit}
      />
      {showModal && (
        <GenericModal
          onClose={() => {
            setShowModal(false);
          }}
          title={t('reset_password.confirm.modal.title')}
          content={
            <>
              <Text bold>{t('reset_password.confirm.modal.subtitle')}</Text>
              <Text>{t('reset_password.confirm.modal.description')}</Text>
            </>
          }
          primaryAction={{
            onClick: () => {
              history.push(ROUTES.RESET_PASSWORD);
            },
            children: t('reset_password.confirm.modal.button'),
          }}
        />
      )}
    </>
  );
};
