import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, IconLinkSlash } from '@coverflex-tech/hypernova';
import EmailVerificationMobile from 'containers/Authentication/components/EmailVerificationMobile';
import AnalyticsService from 'services/analytics';
import AuthService from 'services/auth';
import css from './expired-link-mobile.module.css';

interface IProps {
  token: string;
}

const ExpiredLinkMobile = ({ token }: IProps) => {
  const { t } = useTranslation();
  const [resent, setResent] = useState(false);

  const onResend = async () => {
    AnalyticsService.trackEvent(
      'email_verification_resend_link_button_clicked'
    );
    try {
      await AuthService.resendEmailToken(token);
      setResent(true);
    } catch {}
  };

  if (resent) {
    return <EmailVerificationMobile success token={token} />;
  }

  return (
    <div className={css.host}>
      <div className={css.content}>
        <div className={css.iconBadge}>
          <IconLinkSlash />
        </div>
        <div className={css.title}>{t('email_confirmation.title')}</div>
        <div className={css.description}>
          <Trans
            i18nKey="email_confirmation.description"
            components={[<span key="confirmation" />]}
          />
        </div>
        <div className={css.disclaimer}>
          {t('email_confirmation.disclaimer')}
        </div>
        <Button className={css.button} onClick={onResend}>
          {t('email_confirmation.request_button')}
        </Button>
      </div>
    </div>
  );
};

export default ExpiredLinkMobile;
