import { Control, Controller, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  FloatingPasswordField,
  IFloatingPasswordFieldProps,
} from '@coverflex-tech/hypernova';

interface IProps<T>
  extends Omit<IFloatingPasswordFieldProps, 'id' | 'onChange' | 'value'> {
  name: Path<T>;
  control?: Control;
}

export const ControlledPasswordField = <T,>({
  name,
  control,
  ...restProps
}: IProps<T>) => {
  const { t } = useTranslation();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field }, fieldState }) => (
        <FloatingPasswordField
          {...field}
          {...restProps}
          onChange={field.onChange}
          id={name}
          error={
            fieldState.error?.message ? t(fieldState.error.message) : undefined
          }
        />
      )}
    />
  );
};
