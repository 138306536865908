import { useEffect, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';

export function useResizeObserver({
  onResize,
  debounceMs = 350,
}: {
  onResize: ResizeObserverCallback;
  debounceMs?: number;
}) {
  const targetRef = useRef<HTMLDivElement>(null);
  const debouncedResizeListener = useDebouncedCallback(onResize, debounceMs);

  useEffect(() => {
    const rObserver = new ResizeObserver(debouncedResizeListener);
    if (targetRef.current) {
      rObserver.observe(targetRef.current);
    }
    return () => {
      rObserver.disconnect();
    };
  }, [debouncedResizeListener]);

  return {
    targetRef,
  };
}
