import { REGEX_EMAIL } from 'constants/regex';
import { z } from 'zod';

export const formSchema = z.object({
  email: z
    .string()
    .regex(REGEX_EMAIL, 'reset_password.errors.invalid_email')
    .nonempty('reset_password.errors.required'),
});

export type IResetPasswordFormData = z.infer<typeof formSchema>;
