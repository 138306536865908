import { getCountryName } from 'hooks/useCountries';
import { TAddress } from 'integrations/menhir/business/vouchers/vouchers.types';
import parsePhoneNumber from 'libphonenumber-js';

const formatValueByChunks = (value?: string) => {
  if (!value) {
    return '';
  }

  const chunks = value.match(/\d{1,3}/g);
  return chunks?.join(' ') ?? '';
};

export const formatCFTaxString = (value?: string) => {
  if (!value) {
    return '';
  }
  const chunks = value.match(/(\w{3})(\w{3})(\w{5})(\w{5})/) ?? [];

  const [, ...groups] = chunks; // first item is the full match
  return groups.join(' ');
};

const formatTaxId = formatValueByChunks;

export const buildAddressString = ({
  address,
  fallback = '',
}: { address?: TAddress; fallback?: string } = {}) => {
  if (!address) return fallback;
  const { addressLine1, addressLine2, zipcode, city, country } = address;
  return `${[addressLine1, addressLine2 || ''].join(
    ' '
  )} - ${zipcode} ${city}, ${getCountryName(country) || country}`;
};

const formatPhoneNumber = (phoneNumber?: string) => {
  if (!phoneNumber) {
    return '';
  }

  try {
    const phoneObject = parsePhoneNumber(phoneNumber);
    if (phoneObject) {
      return phoneObject.format('INTERNATIONAL');
    }
    return formatValueByChunks(phoneNumber);
  } catch {
    return '';
  }
};

const formatPostalCode = (postalCode?: string) => {
  if (!postalCode) {
    return '';
  }
  // TODO: Use a specific regex for a given country
  const chunks = postalCode.match(/\d{3,5}/g);
  if (!chunks || chunks.length > 2) {
    // Invalid postal code
    return postalCode;
  }
  return chunks?.join(' - ') ?? postalCode;
};

export const formatToSlug = (value: string) =>
  value
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');

export const StringUtils = {
  formatCFTaxString,
  formatTaxId,
  formatPhoneNumber,
  buildAddressString,
  formatValueByChunks,
  formatPostalCode,
  formatToSlug,
};

export default StringUtils;
