import { t } from 'i18next';

declare global {
  interface Window {
    Vitally: {
      account: (props: { accountId: string; traits: { name: string } }) => void;
      user: (props: {
        accountId: string;
        userId: string;
        traits: { email: string; createdAt?: string };
      }) => void;
      nps: (
        name: string,
        options: {
          productName: string;
          delay: number;
          primaryColor: string;
          npsQuestion: () => string;
          followUpTitle: (props: { score: number }) => string;
          followUpSubtitle: (props: { score: number }) => string;
          thanksTitle: (props: { score: number }) => string;
          thanksSubtitle: (props: { score: number }) => string;
          minLabel: string;
          maxLabel: string;
          placeholderText: (props: { score: number }) => string;
          submitText: string;
          dismissText: string;
        }
      ) => void;
    };
  }
}

interface IVitallyIdentifyUser {
  user: {
    id: string;
    email: string;
  };
  company: {
    id: string;
    name: string;
  };
}

const isDetractor = (score: number) => score <= 6;
const isPassive = (score: number) => score >= 7 && score <= 8;

export const VitallyService = (() => {
  let fired = false;

  const identifyUser = ({ user, company }: IVitallyIdentifyUser) => {
    try {
      window.Vitally.account({
        accountId: company.id,
        traits: {
          name: company.name,
        },
      });

      window.Vitally.user({
        userId: user.id,
        accountId: company.id,
        traits: {
          email: user.email,
        },
      });
      fired = false;
    } catch {}
  };

  const activateSurvey = () => {
    if (fired) return;
    try {
      window.Vitally.nps('survey', {
        productName: 'Coverflex',
        delay: 1000,
        primaryColor: '#FF8E7A',
        npsQuestion: () => t('nps_survey.question'),
        followUpTitle: ({ score }) => {
          if (isDetractor(score)) {
            return t('nps_survey.follow_up.title.detractor');
          }
          if (isPassive(score)) {
            return t('nps_survey.follow_up.title.passive');
          }
          return t('nps_survey.follow_up.title.promoter');
        },
        followUpSubtitle: () => '',
        thanksTitle: () => t('nps_survey.thank_you.title'),
        thanksSubtitle: () => '',
        minLabel: t('nps_survey.min_label'),
        maxLabel: t('nps_survey.max_label'),
        placeholderText: () => '',
        submitText: t('nps_survey.submit'),
        dismissText: t('nps_survey.dismiss'),
      });
      fired = true;
    } catch {}
  };

  return {
    identifyUser,
    activateSurvey,
  };
})();
