import { ReactNode } from 'react';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import { ErrorPage } from 'components/ErrorPage/ErrorPage';

export function ErrorBoundary({ children }: { children: ReactNode }) {
  return (
    <SentryErrorBoundary fallback={<ErrorPage />}>
      {children}
    </SentryErrorBoundary>
  );
}
