import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Alert, Button, Heading, Text } from '@coverflex-tech/hypernova';
import { zodResolver } from '@hookform/resolvers/zod';
import { ROUTES } from 'constants/routes';
import { useAuth } from 'contexts/AuthProvider';
import { AuthenticationLayout } from 'layouts/AuthenticationLayout/AuthenticationLayout';
import { parse } from 'query-string';
import oauthService from 'services/oauth';
import { ControlledTextField } from 'components/Fields/ControlledTextField';
import { ControllerContext } from 'components/Fields/ControllerContext';
import { LoadingPlaceholder } from 'components/LoadingPlaceholder/LoadingPlaceholder';
import { IOAuthLoginFormData, formSchema } from './schema';
import { css } from './OAuthLogin.css';

export const OAuthLogin = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { ssoLogin } = useAuth();

  const { code, state, error } = parse(location.search);
  const [authorizing, setAuthorizing] = useState(!!code && !!state);
  const [invalidAccount, setInvalidAccount] = useState(!!error);

  useEffect(() => {
    (async () => {
      if (code && state) {
        try {
          await ssoLogin(code as string, state as string);
        } catch {
          setInvalidAccount(true);
          setAuthorizing(false);
        }
      }
    })();
  }, [code, state, ssoLogin]);

  const onSubmit = async ({ email }: IOAuthLoginFormData) => {
    try {
      setInvalidAccount(false);
      const { url } = await oauthService.authenticate(email);
      if (url) {
        window.open(url, '_self');
      }
    } catch {
      setInvalidAccount(true);
    }
  };

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: {
      email: '',
    },
    resolver: zodResolver(formSchema),
  });

  if (authorizing) {
    return (
      <div className={css.loading}>
        <LoadingPlaceholder />
      </div>
    );
  }

  return (
    <AuthenticationLayout>
      <Heading level={3} className={css.title}>
        {t('authentication.sso.title')}
      </Heading>
      <Text color="neutral30">{t('authentication.sso.description')}</Text>
      <FormProvider {...methods}>
        <form className={css.form} onSubmit={methods.handleSubmit(onSubmit)}>
          <ControlledTextField
            name="email"
            type="email"
            label={t('authentication.sso.fields.email')}
          />
          {invalidAccount && (
            <Alert type="error">
              <Trans i18nKey="authentication.sso.errors.wrong_email" />
            </Alert>
          )}
          <ControllerContext
            render={({ formState: { isSubmitting } }) => (
              <Button
                type="submit"
                loading={isSubmitting}
                className={css.button}
              >
                {t('authentication.sso.confirm_button')}
              </Button>
            )}
          />
        </form>
      </FormProvider>
      <div className={css.links}>
        <Link to={ROUTES.SIGNIN}>
          <Text underline bold size="sm">
            {t('authentication.sso.link')}
          </Text>
        </Link>
        {import.meta.env.VITE_FLIGHTDECK_URL && (
          <a href={`${import.meta.env.VITE_FLIGHTDECK_URL}`}>
            <Text underline bold size="sm">
              {t('signin.employees_link')}
            </Text>
          </a>
        )}
      </div>
    </AuthenticationLayout>
  );
};
