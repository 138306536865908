import { Control, Controller, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  FloatingTextField,
  IFloatingTextFieldProps,
} from '@coverflex-tech/hypernova';

interface IProps<T>
  extends Omit<IFloatingTextFieldProps, 'id' | 'onChange' | 'value'> {
  name: Path<T>;
  control?: Control;
}

export const ControlledTextField = <T,>({
  name,
  control,
  ...restProps
}: IProps<T>) => {
  const { t } = useTranslation();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field }, fieldState }) => (
        <FloatingTextField
          {...field}
          {...restProps}
          onChange={(evt, formatted) => {
            const { value } = evt.target;
            field.onChange(formatted ?? value);
          }}
          id={name}
          error={
            fieldState.error?.message ? t(fieldState.error.message) : undefined
          }
        />
      )}
    />
  );
};
