import { useEffect, useState } from 'react';
import { Redirect, useParams, useRouteMatch } from 'react-router-dom';
import { downloadFile } from '@coverflex-tech/hypernova-utils';
import { ROUTES } from 'constants/routes';
import digitalArchiveService from 'services/digital-archive';
import { getFile } from 'services/files';
import { LoadingPlaceholder } from 'components/LoadingPlaceholder/LoadingPlaceholder';

interface ILocationParams {
  fileId: string;
}

type DownloadState = 'success' | 'error';

export const DigitalArchive = () => {
  const { fileId } = useParams<ILocationParams>();
  const [state, setState] = useState<DownloadState>();
  const isGeneralFile = !!useRouteMatch(ROUTES.FILES);

  useEffect(() => {
    (async () => {
      try {
        const getter = isGeneralFile
          ? getFile
          : digitalArchiveService.downloadFile;
        const file = await getter(fileId);
        downloadFile(file.url);
        setState('success');
      } catch {
        setState('error');
      }
    })();
  }, [fileId, isGeneralFile]);

  if (state === 'success') {
    return (
      <Redirect
        to={{
          pathname: ROUTES.DASHBOARD_HOME,
          state: {
            downloadedFile: true,
          },
        }}
      />
    );
  }

  if (state === 'error') {
    return <Redirect to={ROUTES.NOT_FOUND} />;
  }

  return <LoadingPlaceholder />;
};
