declare global {
  interface Window {
    grecaptcha: any;
  }
}

export const ELEM_ID = 'cvrflx-recaptcha';

export const RECAPTCHA_URL = 'https://www.google.com/recaptcha/api.js';

export const loadRecaptcha = () => {
  return new Promise((resolve, reject) => {
    const currentScript = document.getElementById(ELEM_ID);
    if (currentScript) {
      window.grecaptcha.ready(() => {
        resolve(currentScript);
      });
      return;
    }

    const script = document.createElement('script');
    script.src = RECAPTCHA_URL;
    script.id = ELEM_ID;
    script.async = true;
    script.onload = () => {
      if (!window.grecaptcha) {
        reject();
        return;
      }
      window.grecaptcha.ready(() => {
        resolve(script);
      });
    };

    document.body.appendChild(script);
  });
};

export const executeRecaptcha = async (action: string) => {
  if (!window.grecaptcha) return;
  const token = await window.grecaptcha.execute({ action });
  return token;
};

export const renderRecaptcha = (elem: HTMLDivElement) => {
  try {
    window.grecaptcha.render(elem, {
      sitekey: import.meta.env.VITE_GOOGLE_RECAPTCHA_KEY,
      size: 'invisible',
    });
  } catch {}
};
