import { useEffect } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import { AppInitProvider } from 'contexts/AppInitProvider';
import { AuthProvider } from 'contexts/AuthProvider';
import { ReactQueryProvider } from 'contexts/ReactQueryProvider';
import { ThemeProvider } from 'contexts/ThemeProvider';
import { ToastProvider } from 'contexts/ToastProvider';
import errorReportService from 'services/error-report';
import localStorageService from 'services/local-storage';
import { SWRConfig } from 'swr';
import { ScrollToTop } from 'utils/ScrollToTop';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import history from './browser-history';
import { PrivateRoute } from './components/PrivateRoute/PrivateRoute';
import { PublicRoute } from './components/PublicRoute';
import { AuthenticationRoutes } from './containers/Authentication/AuthenticationRoutes';
import { Dashboard } from './containers/Dashboard/Dashboard';
import { DigitalArchive } from './containers/DigitalArchive/DigitalArchive';
import { Onboarding } from './containers/Onboarding/Onboarding';
import { PageNotFound } from './containers/PageNotFound/PageNotFound';

if (import.meta.env.PROD) {
  errorReportService.setupReporter(history);
}

const App = () => {
  useEffect(() => {
    window.onfocus = () => {
      const companyId = localStorageService.getSessionCompanyId();
      localStorageService.setLastCompanyId(companyId ?? '');
    };
  }, []);

  return (
    <ThemeProvider>
      <ErrorBoundary>
        <ToastProvider>
          <ReactQueryProvider>
            <SWRConfig
              value={{
                revalidateOnFocus: false,
                errorRetryCount: 0,
              }}
            >
              <AuthProvider>
                <Router history={history}>
                  <AppInitProvider>
                    <ScrollToTop />
                    <Switch>
                      <PrivateRoute path={ROUTES.ONBOARDING}>
                        <Onboarding />
                      </PrivateRoute>
                      <PrivateRoute path={ROUTES.DASHBOARD_HOME}>
                        <Dashboard />
                      </PrivateRoute>
                      <PrivateRoute
                        path={[ROUTES.DIGITAL_ARCHIVE, ROUTES.FILES]}
                      >
                        <DigitalArchive />
                      </PrivateRoute>
                      <PublicRoute path={[ROUTES.SIGNIN, ROUTES.SSO_SIGNIN]}>
                        <AuthenticationRoutes />
                      </PublicRoute>
                      <Route
                        path={[
                          ROUTES.SIGNUP,
                          ROUTES.RESET_PASSWORD,
                          ROUTES.EMAIL_CONFIRMATION,
                        ]}
                      >
                        <AuthenticationRoutes />
                      </Route>
                      <Route exact path={ROUTES.ROOT}>
                        <Redirect to={ROUTES.DASHBOARD_HOME} />
                      </Route>
                      <Route path="*">
                        <PageNotFound />
                      </Route>
                    </Switch>
                  </AppInitProvider>
                </Router>
              </AuthProvider>
            </SWRConfig>
          </ReactQueryProvider>
        </ToastProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default App;
