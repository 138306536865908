import { ReactNode } from 'react';
import { useAuth } from 'contexts/AuthProvider';
import { useGetFeatures } from 'hooks/useGetFeatures';
import { LoadingPlaceholder } from 'components/LoadingPlaceholder/LoadingPlaceholder';

export const AppInitProvider = ({
  children,
  loadingFallback = <LoadingPlaceholder />,
}: TAppInitProviderProps) => {
  const { isInitializing } = useAppInitActions();

  if (isInitializing) {
    return <>{loadingFallback}</>;
  }

  return <>{children}</>;
};

export interface TAppInitProviderProps {
  loadingFallback?: ReactNode;
  children: ReactNode;
}

export const useAppInitActions = () => {
  const { isAuthenticated } = useAuth();
  const { isInitialLoading } = useGetFeatures({ enabled: isAuthenticated });

  return {
    isInitializing: Boolean(isAuthenticated && isInitialLoading),
  };
};
