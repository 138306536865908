import { IWelcomeCreditsConfig } from 'types/auth';
import { ICompanyProfile, OnboardingInfo } from 'types/company';
import {
  IMehhirCompanyRepresentative,
  IMenhirCompanyProfile,
  IMenhirWelcomeCreditsConfig,
  MenhirCompanyRepresentativeType,
  MenhirOnboardingInfo,
} from 'types/menhir';
import { formatDateISO } from 'utils/date';
import { phoneNumberToString } from 'utils/phone-number';
import { IFormData, IRepresentativeData } from 'components/KybForm/utils';

const transformOnboardingInfo = (
  onboardingInfo: MenhirOnboardingInfo | undefined
): OnboardingInfo | undefined => {
  if (!onboardingInfo) return undefined;
  return {
    onboardingOrigin: onboardingInfo.onboarding_origin,
    employeeRange: onboardingInfo.employee_range,
  };
};

export const transformCompany = (
  company: IMenhirCompanyProfile
): ICompanyProfile => ({
  id: company.id,
  name: company.name,
  legalName: company.legal_name ?? '',
  taxId: company.tax_id ?? {
    value: '',
    type: 'nif',
  },
  country: company.country ?? '',
  activityCode: company.activity_code?.value ?? '',
  phoneNumber: company.phone ?? '',
  addresses:
    company.registered_addresses?.map((address) => ({
      type: address.type,
      country: address.country,
      city: address.city,
      district: address.district,
      addressLine1: address.address_line_1,
      zipCode: address.zipcode,
    })) ?? [],
  billingEmail: company.billing_email ?? '',
  language: company.language,
  bankAccount: company.bank_account
    ? {
        number: company.bank_account.number,
        accountHolder: company.bank_account.account_holder,
        bic: company.bank_account.bic,
        status: company.bank_account.status,
      }
    : undefined,
  documents: company.documents ?? [],
  onboarded: company.onboarded,
  logoUrl: company.logo_uri ?? '',
  settings: company.settings
    ? {
        reimbursementsApprovedByCompany:
          company.settings.reimbursements_approved_by_company,
        subscriptionPaymentMethod: company.settings.subscription_payment_method,
        budgetsEnabled: company.settings.budgets_enabled,
        cardShippingStrategy: company.settings.card_shipping_strategy,
        reimbursementHistoryEnabled:
          company.settings.reimbursement_history_enabled,
        includeEmployeeNumberInReports:
          company.settings.include_employee_number_in_reports,
        isCardEnabled: company.settings.is_card_enabled,
      }
    : null,
  plan: company.plan,
  cardDisplayName: company.card_display_name ?? '',
  deedOfIncorporationCode: company.deed_of_incorporation_code,
  insertedAt: company.inserted_at,
  onboardingInfo: transformOnboardingInfo(company.onboarding_info),
  companyDirector: company.company_director
    ? {
        identificationNumber: company.company_director.identification_number,
        name: company.company_director.name,
        birthdate: company.company_director.birthdate,
        residenceCountry: company.company_director.residence_country,
      }
    : undefined,
  companyProxy: company.company_proxy
    ? {
        identificationNumber: company.company_proxy.identification_number,
        name: company.company_proxy.name,
        birthdate: company.company_proxy.birthdate,
        residenceCountry: company.company_proxy.residence_country,
      }
    : undefined,
  market: {
    ...company.market,
    languages: company.market.languages ?? [],
  },
  properties: company.properties
    ? {
        sdiCode: company.properties.sdi_code,
        businessPartitaIva: company.properties.business_partita_iva,
        pec: company.properties.pec,
      }
    : undefined,
  representatives: company.representatives
    ? company.representatives.map((person) => ({
        type: person.type,
        identificationNumber: person.identification_number,
        name: person.name,
        residenceCountry: person.residence_country,
        birthdate: person.birthdate,
        nationality: person.nationality,
        phoneNumber: person.phone,
      }))
    : undefined,
  kybStatus: company.kyb_status,
});

const buildRepresentative =
  (type: MenhirCompanyRepresentativeType) =>
  (person: IRepresentativeData): IMehhirCompanyRepresentative => ({
    type,
    identification_number: person.idNumber,
    name: person.name,
    residence_country: person.country?.iso2 ?? '',
    birthdate: formatDateISO(person.birthDate),
    nationality: person.nationality?.iso2 ?? '',
    phone: phoneNumberToString(person.phoneNumber),
  });

export const buildRepresentativesPayload = ({
  managers,
  shareholders,
}: IFormData) => ({
  representatives: [
    ...managers.map(buildRepresentative('manager')),
    ...shareholders.map(buildRepresentative('shareholder')),
  ],
});

const transformWelcomeCreditsConfig = (
  creditsConfig: IMenhirWelcomeCreditsConfig | null
): IWelcomeCreditsConfig | null => {
  if (creditsConfig) {
    return {
      bonusAmount: creditsConfig.bonus_amount,
      campaignStartDate: creditsConfig.campaign_start_date,
      minimumTopUpAmount: creditsConfig.minimum_top_up_amount,
    };
  }
  return null;
};

export default { transformCompany, transformWelcomeCreditsConfig };
