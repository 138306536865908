export const ROUTES = {
  ROOT: '/',
  SIGNIN: '/signin',
  SSO_SIGNIN: '/oauth2/signin',
  SIGNUP: '/signup',
  SIGNUP_IT: '/signup/it',
  RESET_PASSWORD: '/reset-password',
  EMAIL_CONFIRMATION: '/email-confirmation',
  DASHBOARD_HOME: '/dashboard',
  DASHBOARD_EMPLOYEES: '/dashboard/employees',
  EMPLOYEE_DETAILS: '/dashboard/employees/:employeeId',
  EMPLOYEE_DETAILS_FAMILY: '/dashboard/employees/:employeeId/family',
  EMPLOYEE_DETAILS_MEAL: '/dashboard/employees/:employeeId/meal',
  EMPLOYEE_DETAILS_BENEFITS: '/dashboard/employees/:employeeId/benefits',
  EMPLOYEE_DETAILS_BUDGETS: '/dashboard/employees/:employeeId/budgets',
  EMPLOYEE_DETAILS_EARNED_WAGE_ACCESS: '/dashboard/employees/:employeeId/ewa',
  EMPLOYEE_DETAILS_INSURANCE: '/dashboard/employees/:employeeId/insurance',
  EMPLOYEE_NEW_FAMILY_MEMBER: '/dashboard/employees/:employeeId/new-member',
  EMPLOYEE_FUNDS_TRANSFER: '/dashboard/employees/:employeeId/transfer',
  EMPLOYEE_ADD_BUDGET: '/dashboard/employees/:employeeId/attribute-budget',
  EMPLOYEES_ADD_UPLOAD: '/dashboard/employees/add/upload',
  EMPLOYEES_REMOVE_UPLOAD: '/dashboard/employees/remove/upload',
  EMPLOYEES_ADD: '/dashboard/employees/add',
  EMPLOYEES_ENROL: '/dashboard/employees/enrol',
  DASHBOARD_EWA: '/dashboard/ewa',
  DASHBOARD_WALLET: '/dashboard/wallet',
  WALLET_TOP_UP_CARDS: '/dashboard/wallet/top-up-cards',
  WALLET_CONFIRM_TOP_UP_CARDS: '/dashboard/wallet/top-up-cards/confirm',
  WALLET_TOP_UP_CARDS_DETAILS: '/dashboard/wallet/top-up-cards/:topupId',
  WALLET_REPORTS: '/dashboard/wallet/reports',
  WALLET_REQUEST_REPORT: '/dashboard/wallet/request_report',
  MEAL_HOME: '/dashboard/meal',
  EMPLOYEES_INVITE: '/dashboard/employees/invite',
  WALLET_RETIREMENT_INVESTMENT:
    '/dashboard/wallet/retirement-investment/:orderId',
  WALLET_REIMBURSEMENTS_DETAILS:
    '/dashboard/wallet/:categorySlug/reimbursements/:orderId/request-details',
  WALLET_REIMBURSEMENTS_HISTORY:
    '/dashboard/wallet/:categorySlug/reimbursements/:orderId/request-history',
  WALLET_APPLE: '/dashboard/wallet/apple/:orderId',
  WALLET_TRANSACTIONS: '/dashboard/wallet/transactions',
  WALLET_REIMBURSEMENT_ORDERS: '/dashboard/wallet/reimbursements',
  WALLET_BUDGET_ORDERS: '/dashboard/wallet/budgets',
  WALLET_APPLE_ORDERS: '/dashboard/wallet/apple-orders',
  WALLET_FLEXPAY_TRANSFERS: '/dashboard/wallet/flexpay-transfers',
  WALLET_PAYMENTS: '/dashboard/wallet/payments',
  DASHBOARD_INSURANCE: '/dashboard/insurance',
  NEW_INSURANCE_MIGRATION: '/dashboard/insurance/:categorySlug/migration/new',
  INSURANCE_MIGRATION_CONTRACT: '/dashboard/insurance/migration/:id/contract',
  INSURANCE_POLICY_DETAIL: '/dashboard/insurance/policy/:id',
  INSURANCE_POLICY_HOLIDAY_SHEET:
    '/dashboard/insurance/policy/:id/upload-sheet',
  INSURANCE_ADD_PEOPLE: '/dashboard/insurance/policy/:id/add',
  INSURANCE_POLICY_COMPLETE_INFO:
    '/dashboard/insurance/policy/:id/complete-info',
  DASHBOARD_BILLING: '/dashboard/billing',
  BILLING_HEALTH_INSURANCE: '/dashboard/billing/health-insurance',
  BILLING_WORKERS_COMPENSATION: '/dashboard/billing/workers-compensation',
  FAQ: '/dashboard/FAQ',
  DASHBOARD_SETTINGS: '/dashboard/settings',
  SETTINGS_COMPANY: '/dashboard/settings/company',
  SETTINGS_DOCUMENTS: '/dashboard/settings/documents',
  SETTINGS_GENERAL: '/dashboard/settings/general',
  SETTINGS_BILLING: '/dashboard/settings/billing',
  SETTINGS_CARDS_SHIPPING: '/dashboard/settings/cards-shipping',
  ONBOARDING: '/onboarding',
  ONBOARDING_WELCOME: '/onboarding/welcome',
  ONBOARDING_COMPANY_INTRO: '/onboarding/company/intro',
  ONBOARDING_COMPANY: '/onboarding/company',
  ONBOARDING_MANAGERS: '/onboarding/managers',
  ONBOARDING_DOCUMENTS: '/onboarding/documents',
  ONBOARDING_PEOPLE_INTRO: '/onboarding/people/intro',
  ONBOARDING_PEOPLE: '/onboarding/people',
  ONBOARDING_PEOPLE_MANUAL: '/onboarding/people/manual',
  ONBOARDING_PEOPLE_UPLOAD: '/onboarding/people/upload',
  ONBOARDING_PEOPLE_CONFIRM: '/onboarding/people/confirm',
  ONBOARDING_WALLET: '/onboarding/wallet',
  ONBOARDING_WALLET_SETUP: '/onboarding/wallet/setup',
  ONBOARDING_CARD: '/onboarding/card',
  ONBOARDING_SHIPPING: '/onboarding/shipping',
  ONBOARDING_PLAN: '/onboarding/plan',
  ONBOARDING_PAYMENT: '/onboarding/payment',
  ONBOARDING_SUBSCRIPTION_INTRO: '/onboarding/subscription/intro',
  ONBOARDING_SUBSCRIPTION_ENROL: '/onboarding/subscription/enrol',
  ONBOARDING_SUBSCRIPTION_CONFIRM: '/onboarding/subscription/confirm',
  ONBOARDING_INVITATIONS_INTRO: '/onboarding/invitations/intro',
  ONBOARDING_INVITATIONS_SEND: '/onboarding/invitations/send',
  NOT_FOUND: '/not-found',
  DASHBOARD_BENEFITS: '/dashboard/benefits',
  DASHBOARD_BENEFITS_POLICY: '/dashboard/benefits/policy',
  BENEFIT_DETAIL: '/dashboard/benefits/:slug',
  INSURANCE_BENEFIT_DETAIL: '/dashboard/benefits/insurance/:slug',
  DEPOSIT_INSTRUCTIONS: '/dashboard/deposit',
  WITHDRAW_FUNDS: '/dashboard/withdraw',
  IBAN_VALIDATION: '/dashboard/iban-validation',
  MEAL_TOP_UP_CARDS: '/dashboard/meal/top-up-cards',
  MEAL_TOP_UP_DETAILS: '/dashboard/meal/top-up/:id',
  DIGITAL_ARCHIVE: '/digital-archive/:fileId',
  FILES: '/files/:fileId',
  DASHBOARD_BUDGETS: '/dashboard/budgets',
  BUDGETS_CREATE: '/dashboard/budgets/new',
  BUDGET_DETAIL: '/dashboard/budgets/:id',
  BUDGET_EDIT: '/dashboard/budgets/:id/edit',
  BUDGET_ADD_EMPLOYEES: '/dashboard/budgets/:id/add-people',
  BUDGET_EDIT_AMOUNT: '/dashboard/budgets/:budgetId/:employeeId/edit-amount',
  BUDGET_NEW_EXPENSE: '/dashboard/budgets/:budgetId/:employeeId/new-expense',
  BUDGET_EXPENSE_DETAIL: '/dashboard/budgets/:budgetId/expenses/:expenseId',
  BUDGET_APPLICATION_DETAIL: '/dashboard/budgets/applications/:id',
  DASHBOARD_MEAL_VOUCHER: '/dashboard/meal-voucher',
  MEAL_VOUCHER_TOP_UP: '/dashboard/meal-voucher/top-up',
  DASHBOARD_DISCOVER: '/dashboard/discover',
  WELFARE_HOME: '/dashboard/welfare',
  WELFARE_POLICY: '/dashboard/welfare/policy',
  WELFARE_TOP_UP: '/dashboard/welfare/top-up',
  WELFARE_DETAIL: '/dashboard/welfare/:slug',
  DASHBOARD_KYB: '/dashboard/kyb',
  DASHBOARD_TRANSACTIONS: '/dashboard/transactions',
  TRANSACTIONS_COMPANY: '/dashboard/transactions/company',
  TRANSACTIONS_WELFARE: '/dashboard/transactions/welfare',
  TRANSACTION_DETAIL: '/dashboard/transactions/:id',
  DASHBOARD_REQUESTS: '/dashboard/requests',
  REQUESTS_DETAIL: '/dashboard/requests/:orderId',
  REPORTS_HOME: '/dashboard/reports',
  REPORTS_REQUEST: '/dashboard/reports/request',
  EWA_SETUP: '/dashboard/ewa/setup',
  EWA_EDIT_PAYROLL: '/dashboard/ewa/edit-payroll',
  EWA_MANAGE_PEOPLE: '/dashboard/ewa/manage-people',
  DASHBOARD_INSIGHTS: '/dashboard/insights',
  FLEXIBLE_RETRIBUTION: '/dashboard/flexible-retribution',
  FLEXIBLE_RETRIBUTION_SETUP: '/dashboard/flexible-retribution/setup',
  FLEXIBLE_RETRIBUTION_EDIT: '/dashboard/flexible-retribution/edit',
  SOCIAL_BENEFITS_SETUP: '/dashboard/social-benefits/setup',
  SOCIAL_BENEFITS_EDIT: '/dashboard/social-benefits/edit',
  FRINGE_TOPUP: '/dashboard/fringe/topup',
} as const satisfies Record<string, string>;

export type TRoute = (typeof ROUTES)[keyof typeof ROUTES];

export type TRouteParamsArray<R extends string> =
  R extends `${string}:${infer P2}` ? [string, ...TRouteParamsArray<P2>] : [];

export type TRouteParamsRecord<Path> =
  Path extends `${infer Segment}/${infer Rest}`
    ? Segment extends `:${infer Param}`
      ? Record<Param, string> & TRouteParamsRecord<Rest>
      : TRouteParamsRecord<Rest>
    : Path extends `:${infer Param}`
      ? Record<Param, string>
      : {};

export const routeFor = <R extends string = TRoute>(
  route: R,
  ...args: TRouteParamsArray<R>
) => {
  return args.reduce<string>((r, arg) => r.replace(/:\w+/, arg), route);
};
