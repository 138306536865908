import { Trans, useTranslation } from 'react-i18next';
import { Button, IconDesktop } from '@coverflex-tech/hypernova';
import css from './mobile-login.module.css';

interface IProps {
  verified?: boolean;
}

const MobileLogin = ({ verified }: IProps) => {
  const { t } = useTranslation();
  return (
    <div className={css.host}>
      <div className={css.content}>
        <div className={css.iconBadge}>
          <IconDesktop />
        </div>
        <div className={css.title}>
          {t(`signin.mobile.${verified ? 'title_verified' : 'title'}`)}
        </div>
        <div className={css.description}>
          <Trans
            i18nKey={`signin.mobile.${
              verified ? 'description_verified' : 'description'
            }`}
          />
        </div>
        <a
          href="https://coverflex.com"
          rel="noopener noreferrer"
          className={css.link}
        >
          <Button presentational className={css.button}>
            {t('signin.mobile.button')}
          </Button>
        </a>
      </div>
    </div>
  );
};

export default MobileLogin;
