import { REGEX_EMAIL } from 'constants/regex';
import { z } from 'zod';

export const formSchema = z.object({
  email: z
    .string()
    .regex(REGEX_EMAIL, 'signin.errors.invalid_email')
    .nonempty('signin.errors.required'),
  password: z.string().nonempty('signin.errors.required'),
});

export type ILoginFormData = z.infer<typeof formSchema>;
