import { ReactNode, createContext, useContext, useRef, useState } from 'react';
import { useResizeObserver } from 'hooks/useResizeObserver';
import { GlobalAlertFailedSubscriptionPayment } from 'components/GlobalDashboardAlert/GlobalAlertFailedSubscriptionPayment';
import { css } from './GlobalDashboardAlertsProvider.css';

export type TGlobalAlertFailedSubscriptionPaymentProps = {
  children: ReactNode;
};

interface GlobalAlertsContextProps {
  active: boolean;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const GlobalAlertsContext = createContext<GlobalAlertsContextProps>({
  active: false,
  setActive: () => {},
});

export const useGlobalAlerts = () => {
  const context = useContext(GlobalAlertsContext);
  return context;
};

export const GlobalDashboardAlertsProvider = ({
  children,
}: TGlobalAlertFailedSubscriptionPaymentProps) => {
  const slotRef = useRef<HTMLDivElement | null>(null);
  const [active, setActive] = useState(false);

  const { targetRef } = useResizeObserver({
    onResize: () => {
      if (targetRef.current && slotRef.current) {
        slotRef.current.style.height = `${
          targetRef.current.clientHeight / 10
        }rem`;
      }
    },
  });

  return (
    <GlobalAlertsContext.Provider value={{ active, setActive }}>
      <div className={css.alertsContainer} ref={targetRef}>
        <GlobalAlertFailedSubscriptionPayment />
      </div>
      <div ref={slotRef} />
      {children}
    </GlobalAlertsContext.Provider>
  );
};
