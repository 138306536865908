import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import { useAuth } from 'contexts/AuthProvider';

export const PublicRoute = (routeProps: RouteProps) => {
  const { state } = useLocation();
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: ROUTES.DASHBOARD_HOME,
          state,
        }}
      />
    );
  }
  return <Route {...routeProps} />;
};
