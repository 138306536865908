import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DramaticAlert } from '@coverflex-tech/hypernova';
import { MARKET } from 'constants/markets';
import { ROUTES } from 'constants/routes';
import { useUser } from 'contexts/AuthProvider';
import { useGlobalAlerts } from 'contexts/GlobalDashboardAlertsProvider/GlobalDashboardAlertsProvider';
import { useSubscription } from 'hooks/useSubscription';
import { css } from './GlobalAlertFailedSubscriptionPayment.css';

export const GlobalAlertFailedSubscriptionPayment = () => {
  const { user } = useUser();
  const { subscription } = useSubscription();
  const { setActive } = useGlobalAlerts();

  const payingMethod = user.company.settings?.subscriptionPaymentMethod;

  const isFailedPayment =
    subscription?.lastInvoice?.status === 'failed' ||
    subscription?.lastInvoice?.status === 'due';

  if (isFailedPayment && user.company.market.slug === MARKET.PT) {
    setActive(true);
    return (
      <DramaticAlert
        content={
          <Trans
            i18nKey={`global_alerts.dashboard.subscription.failed_payment.${payingMethod}.warning`}
            parent="div"
            components={[
              <Link
                to={{
                  pathname: ROUTES.DASHBOARD_BILLING,
                  state:
                    payingMethod === 'card'
                      ? {
                          invoice: subscription?.lastInvoice,
                        }
                      : undefined,
                }}
                key="trial_employees"
                className={css.link}
              />,
            ]}
          />
        }
      />
    );
  } else if (
    isFailedPayment &&
    user.company.market.slug === MARKET.IT &&
    payingMethod === 'card'
  ) {
    setActive(true);
    return (
      <DramaticAlert
        content={
          <Trans
            parent="div"
            i18nKey="global_alerts.dashboard.subscription.failed_payment.card.warning"
            components={[
              <Link
                to={{
                  pathname: ROUTES.DASHBOARD_BILLING,
                  state: {
                    invoice: subscription?.lastInvoice,
                  },
                }}
                key="trial_employees"
                className={css.link}
              />,
            ]}
          />
        }
      />
    );
  } else {
    return null;
  }
};
