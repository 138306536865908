import { TPlan } from 'constants/markets';
import subscriptionDTO from 'dto/subscription';
import { api } from 'integrations/menhir/api-client';
import {
  IMenhirSubscriptionEnrollmentsEnvelope,
  IMenhirUpdatedSubscription,
  MenhirSubscription,
  MenhirSubscriptionPlan,
} from 'types/menhir';
import { ISubscriptionPeriod } from 'types/subscription';

export const PREFIX_SUBSCRIPTION = '/business/subscription';
export const PREFIX_PLAN = '/business/plan';
export const PREFIX_INVOICES = '/business/invoices';
export const API_ROUTES = {
  SUBSCRIPTION: `${PREFIX_SUBSCRIPTION}`,
  SUBSCRIPTION_PLAN: `${PREFIX_SUBSCRIPTION}/plan`,
  PLAN: `${PREFIX_PLAN}`,
  SUBSCRIPTION_INVOICES: `${PREFIX_INVOICES}`,
  SUBSCRIBED_EMPLOYEES: `${PREFIX_SUBSCRIPTION}/enrollments`,
  REEQUEST_CANCELLATION: `${PREFIX_SUBSCRIPTION}/cancellation-requested`,
  PLANS: `${PREFIX_SUBSCRIPTION}/plans`,
};

interface NewSubscriptionPayload {
  employee_ids: string[];
  period: ISubscriptionPeriod;
  plan_id: string;
}

interface SubscriptionEnvelope {
  subscription: MenhirSubscription;
}
interface SubscriptionPlanEnvelope {
  plan: MenhirSubscriptionPlan;
}

const getSubscription = (params?: { status?: 'any' }) =>
  api
    .get<null, SubscriptionEnvelope>(API_ROUTES.SUBSCRIPTION, {
      params,
    })
    .then((envelope) =>
      subscriptionDTO.transformSubscription(envelope.subscription)
    )
    .catch((e) => {
      if (e.statusCode === 404) {
        return null;
      }
      throw e;
    });

const createSubscription = (payload: NewSubscriptionPayload) => {
  return api
    .post<
      NewSubscriptionPayload,
      SubscriptionEnvelope
    >(API_ROUTES.SUBSCRIPTION, payload)
    .then((envelope) =>
      subscriptionDTO.transformSubscription(envelope.subscription)
    );
};

const getSubscriptionPlan = () =>
  api
    .get<null, SubscriptionPlanEnvelope>(API_ROUTES.PLAN)
    .then((envelope) =>
      subscriptionDTO.transformSubscriptionPlan(envelope.plan)
    );

export const getSubscribedEmployees = () =>
  api
    .get<
      null,
      IMenhirSubscriptionEnrollmentsEnvelope
    >(API_ROUTES.SUBSCRIBED_EMPLOYEES)
    .then((envelope) =>
      envelope.enrollments.map(subscriptionDTO.transformSubscriptionEnrollment)
    );

const addEmployeesToSubscription = (employees: string[], validate?: boolean) =>
  api
    .post<null, IMenhirUpdatedSubscription>(
      API_ROUTES.SUBSCRIBED_EMPLOYEES,
      {
        employee_ids: employees,
      },
      {
        params: {
          validate,
        },
      }
    )
    .then(subscriptionDTO.transformUpdatedSubscription);

const requestSubscriptionCancellation = () =>
  api.post(API_ROUTES.REEQUEST_CANCELLATION);

const getSubscriptionPlans = () =>
  api
    .get<null, { plans: MenhirSubscriptionPlan[] }>(API_ROUTES.PLANS)
    .then(({ plans }) => plans.map(subscriptionDTO.transformSubscriptionPlan));

export const updateSubscriptionPlan = (plan: TPlan) =>
  api.put<null, null>(API_ROUTES.SUBSCRIPTION_PLAN, { plan });

export const SubscriptionService = {
  requestSubscriptionCancellation,
  getSubscribedEmployees,
  getSubscription,
  getSubscriptionPlan,
  createSubscription,
  addEmployeesToSubscription,
  getSubscriptionPlans,
  updateSubscriptionPlan,
};
