import { ReactNode, isValidElement } from 'react';
import { ToastOptions, toast } from 'react-hot-toast';
import { Trans } from 'react-i18next';
import { type IToastProps, Toast } from '@coverflex-tech/hypernova';
import { VitallyService } from 'integrations/vitally/service';
import css from './toast.module.css';

export const notify = ({ message, type = 'info', ...opts }: TNotifyArgs) => {
  const finalMessage = isValidElement(message) ? (
    message
  ) : (
    <Trans i18nKey={message as string} />
  );

  if (type === 'success') {
    VitallyService.activateSurvey();
  }

  return toast.custom(({ id, visible }) => {
    // Until https://github.com/timolins/react-hot-toast/issues/116 is fixed
    if (!visible) {
      return null;
    }

    return (
      <div className={visible ? css.in : css.out}>
        <Toast
          message={finalMessage}
          type={type}
          onClose={() => toast.dismiss(id)}
          action={opts?.action}
          icon={opts?.icon}
        />
      </div>
    );
  }, opts);
};

export type TNotifyArgs = {
  message: string | ReactNode;
  type?: IToastProps['type'];
} & ToastOptions &
  Omit<IToastProps, 'message' | 'type' | 'onClose'>;
