import { ReactNode, useEffect } from 'react';
import { TTheme, themeClass } from '@coverflex-tech/hypernova';

const theme: TTheme = 'light';
export function ThemeProvider({ children }: { children: ReactNode }) {
  useEffect(() => {
    document.body.classList.add(themeClass[theme]);
  }, []);

  return <>{children}</>;
}
