import { REGEX_EMAIL } from 'constants/regex';
import { z } from 'zod';

export const formSchema = z.object({
  email: z
    .string()
    .regex(REGEX_EMAIL, 'authentication.sso.errors.invalid_email')
    .nonempty('authentication.sso.errors.required'),
});

export type IOAuthLoginFormData = z.infer<typeof formSchema>;
