import { useEffect, useState } from 'react';
import { MOBILE_LANDSCAPE } from 'constants/breakpoints';
import { TPlan } from 'constants/markets';
import EmailVerificationMobile from 'containers/Authentication/components/EmailVerificationMobile';
import { SignupForm } from 'containers/Authentication/components/SignupForm/SignupForm';
import useMediaQuery from 'hooks/useMediaQuery';
import AnalyticsService from 'services/analytics';
import { ISignupManager } from 'types/auth';
import EmailVerification from './components/EmailVerification';

type Props = {
  plan: TPlan;
};

export const SelfSignUp = ({ plan }: Props) => {
  const [manager, setManager] = useState<ISignupManager>();

  const isMobile = useMediaQuery(MOBILE_LANDSCAPE);

  const onFormSubmit = (signupManager: ISignupManager) => {
    setManager(signupManager);
  };

  useEffect(() => {
    if (manager) {
      AnalyticsService.trackPageView('email_verification_page');
    }
  }, [manager]);

  return (
    <div>
      {!manager ? (
        <SignupForm onSubmit={onFormSubmit} plan={plan} />
      ) : isMobile ? (
        <EmailVerificationMobile manager={manager} />
      ) : (
        <EmailVerification manager={manager} />
      )}
    </div>
  );
};
