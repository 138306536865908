import { isBefore } from 'date-fns';
import { t } from 'i18next';
import { TMoney } from 'types/billing';
import { z } from 'zod';
import { now, transformSchemaDate } from './date';

export const requiredString = z.string().min(1, t('form_errors.required'));

export const zodMoneySchema = z.object({
  amount: z.number(),
  currency: z.string(),
});

export const requiredMoneySchema = z
  .object(
    {
      amount: z.number(),
      currency: z.string(),
    },
    { message: 'form.errors.required' }
  )
  .nullable()
  .refine(
    (value): value is TMoney =>
      !!value && value.amount !== null && value.amount !== undefined,
    {
      message: 'form.errors.required',
    }
  );

export const minRequiredMoneySchema = requiredMoneySchema.refine(
  (value) => value && value.amount > 0,
  {
    message: 'form.errors.positive_value',
  }
);

export const fileSchema = z.object({
  id: z.string().optional(),
  name: z.string(),
  url: z.string(),
  slug: z.string().optional(),
  blob: z.instanceof(File).optional(),
  contentType: z.string().optional(),
});

export const birthDateTextSchema = requiredString.superRefine((value, ctx) => {
  const date = transformSchemaDate(value);

  if (!(date instanceof Date)) {
    return ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'form.errors.invalid_date',
    });
  }

  if (!isBefore(date, now())) {
    return ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'form.errors.max_date',
    });
  }
});
