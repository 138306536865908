import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { API_ROUTES, getFeatures } from 'services/features';
import { IFeatures } from 'types/features';

export const useGetFeatures = (config: UseQueryOptions<IFeatures> = {}) => {
  const { data: features = DEFAULT_FEATURES, ...rest } = useQuery<IFeatures>({
    queryKey: [API_ROUTES.FEATURES],
    queryFn: getFeatures,
    keepPreviousData: true,
    staleTime: 1000 * 30,
    ...config,
  });
  return { features, ...rest };
};

export const DEFAULT_FEATURES: IFeatures = {
  FF_BUDGET_WITH_APPLICATION: false,
  FF_CARD: false,
  FF_FILE_BANKING_PROVIDER: false,
  FF_REIMBURSEMENT_HISTORY_ENABLED: false,
  FF_UPSELLING_INSURANCE_BANNER: false,
  FF_USE_NEW_REONBOARDING: false,
  FF_WORKERS_COMPENSATION_DEACTIVATION: false,
  FF_UPSELLING_INSURANCE_WALLET_BANNER: false,
  FF_UPSELLING_INSURANCE_BANNER_VARIATION: false,
  FF_CARD_INVOICING: false,
  FF_FRINGE_INVOICED_IN_ADVANCE: false,
  FF_INCLUDE_EMPLOYEE_NUMBER_IN_REPORTS: false,
  FF_BULK_OFFBOARDING: false,
  FF_CAN_ADD_FAMILY_MEMBERS: false,
  FF_IMPROVE_QUERY_PERFORMANCE: false,
  FF_LLUNI_INTEGRATION: false,
  FF_ES_REPORTS_PAGE: false,
};
