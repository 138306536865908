import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Heading } from '@coverflex-tech/hypernova';
import { ROUTES } from 'constants/routes';
import { CreatePasswordForm } from 'containers/Authentication/components/CreatePasswordForm/CreatePasswordForm';
import { ICreatePasswordFormData } from 'containers/Authentication/components/CreatePasswordForm/schema';
import { CreatedAccountModal } from 'containers/Authentication/components/CreatedAccountModal/CreatedAccountModal';
import InvalidTokenModal from 'containers/Authentication/components/InvalidTokenModal';
import { useAuth } from 'contexts/AuthProvider';
import usePageViewTracking from 'hooks/usePageViewTracking';
import AnalyticsService from 'services/analytics';
import authService from 'services/auth';
import { RequestError } from 'types/general';
import HelpLink from 'components/HelpLink';

interface IModal {
  type: 'success' | 'error';
  sso: boolean;
}

type WalletCreatePasswordProps = {
  token: string;
  isSSO: boolean;
};

export const WalletSignUp = ({ token, isSSO }: WalletCreatePasswordProps) => {
  const { t } = useTranslation();
  const { updateSession, logout } = useAuth();
  usePageViewTracking('create_password_page');

  const [validating, setValidating] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [modal, setModal] = useState<IModal>();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      if (validating) {
        try {
          const { token: authToken, refresh_token } =
            await authService.validateInvitation(token);
          if (isSSO) {
            setModal({
              type: 'success',
              sso: true,
            });
            setValidating(false);
            return;
          }
          // Multi company scenario -> Login with new account
          updateSession(authToken, refresh_token);
          history.push(ROUTES.DASHBOARD_HOME, { newCompanyAccess: true });
        } catch (e) {
          const error = e as RequestError;
          if (error.errorCode === 'not_found') {
            setModal({
              type: 'error',
              sso: isSSO,
            });
          }
          setValidating(false);
        }
      }
    })();
  }, [token, isSSO, updateSession, history, validating]);

  const handleSubmit = useCallback(
    async (form: ICreatePasswordFormData) => {
      const { token, password, passwordConfirmation } = form;

      if (password !== passwordConfirmation) return;

      setSubmitting(true);
      AnalyticsService.trackEvent('create_password_button_clicked');
      try {
        await authService.signUp(token, password);
        logout();
        setModal({
          type: 'success',
          sso: false,
        });
      } catch (e) {
        const error = e as RequestError;
        if (error?.errorCode === 'not_found') {
          setModal({
            type: 'error',
            sso: false,
          });
        }
      } finally {
        setSubmitting(false);
      }
    },
    [logout]
  );

  if (validating) {
    return null;
  }

  return (
    <div>
      {modal?.type === 'success' && <CreatedAccountModal sso={modal.sso} />}
      {modal?.type === 'error' && <InvalidTokenModal sso={modal.sso} />}
      <Heading level={3}>{t('signup.title')}</Heading>
      <CreatePasswordForm
        token={token}
        disclaimer={
          <Trans
            i18nKey="signup.disclaimer"
            components={[
              <HelpLink key={0} i18nKey="help_link.terms_conditions">
                &nbsp;
              </HelpLink>,
              <HelpLink key={1} i18nKey="help_link.privacy_policy">
                &nbsp;
              </HelpLink>,
            ]}
          ></Trans>
        }
        buttonLabel={t('signup.button')}
        submitting={submitting}
        onSubmit={handleSubmit}
      />
    </div>
  );
};
