import { Trans, useTranslation } from 'react-i18next';
import { GenericModal, IconExclamation } from '@coverflex-tech/hypernova';
import css from './session-expired-modal.module.css';

interface IProps {
  onClose: () => void;
}

const SessionExpiredModal = ({ onClose }: IProps) => {
  const { t } = useTranslation();

  return (
    <GenericModal
      iconBadge={
        <div className={css.badge}>
          <IconExclamation />
        </div>
      }
      title={t('session_expired_modal.title')}
      content={<Trans i18nKey="session_expired_modal.description" />}
      onClose={onClose}
      primaryAction={{
        onClick: onClose,
        children: t('session_expired_modal.button_label'),
      }}
    />
  );
};

export default SessionExpiredModal;
