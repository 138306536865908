import { useEffect, useRef, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import {
  Alert,
  Button,
  FloatingPasswordField,
  Heading,
  Text,
} from '@coverflex-tech/hypernova';
import { zodResolver } from '@hookform/resolvers/zod';
import { marketLanguagesMapper } from 'constants/languages';
import { MARKET, PLAN, TMarket, TPlan } from 'constants/markets';
import { ROUTES } from 'constants/routes';
import usePageViewTracking from 'hooks/usePageViewTracking';
import { parse } from 'query-string';
import AnalyticsService from 'services/analytics';
import auth from 'services/auth';
import { EmployeeRange, ISignupManager, ISignupRequest } from 'types/auth';
import { LanguageType } from 'types/general';
import {
  executeRecaptcha,
  loadRecaptcha,
  renderRecaptcha,
} from 'utils/recaptcha';
import { getTaxIdTypeForMarket } from 'utils/tax';
import { ControlledPasswordField } from 'components/Fields/ControlledPasswordField';
import { ControlledSelectField } from 'components/Fields/ControlledSelectField';
import { ControlledTextField } from 'components/Fields/ControlledTextField';
import { ControllerContext } from 'components/Fields/ControllerContext';
import HelpLink from 'components/HelpLink';
import { getPasswordHint } from '../CreatePasswordForm/CreatePasswordForm';
import { EMPLOYEE_RANGE_VALUES, ISignupFormData, formSchema } from './schema';
import { getHelpLinks } from './utils';
import { css } from './SignupForm.css';

interface IProps {
  onSubmit: (manager: ISignupManager) => void;
  plan: TPlan;
  market?: TMarket;
}

const getParamString = (
  queryParam: string | string[] | null,
  defaultValue: string = ''
): string => {
  const value = Array.isArray(queryParam) ? queryParam[0] : queryParam;
  return value ?? defaultValue;
};

export const SignupForm = ({ onSubmit, plan, market = MARKET.PT }: IProps) => {
  usePageViewTracking('create_account_page', {
    plan: plan === PLAN.WALLET ? PLAN.WALLET : 'meal',
  });

  const { t, i18n } = useTranslation();
  const location = useLocation();
  const recaptchaRef = useRef<HTMLDivElement | null>(null);
  const [hasError, setHasError] = useState(false);
  const {
    email,
    company,
    numEmployees,
    utm_source,
    utm_medium,
    utm_campaign,
    utm_content,
  } = parse(location.search);

  const handleSubmit = async (values: ISignupFormData) => {
    setHasError(false);
    const currentLanguage = i18n.language as LanguageType;
    try {
      const payload: ISignupRequest = {
        ...values,
        market,
        plan,
        language: marketLanguagesMapper[market].includes(currentLanguage)
          ? currentLanguage
          : 'en-GB',
      };
      const recaptachaToken = await executeRecaptcha('signup');
      const manager = await auth.companySignUp(payload, recaptachaToken);

      AnalyticsService.trackEvent('signed_up', {
        email: values.email,
        company_name: values.company,
        tax_number: values.taxId,
        number_employees_range: values.employeeRange,
        plan: plan,
        market,
        utm_source: getParamString(utm_source),
        utm_medium: getParamString(utm_medium),
        utm_campaign: getParamString(utm_campaign),
        utm_content: getParamString(utm_content),
      });

      onSubmit(manager);
    } catch {
      setHasError(true);
    }
  };

  const methods = useForm<ISignupFormData>({
    mode: 'onBlur',
    defaultValues: {
      company: getParamString(company),
      taxId: '',
      taxType: getTaxIdTypeForMarket(market),
      email: getParamString(email),
      password: '',
      passwordConfirmation: '',
      employeeRange: numEmployees
        ? (getParamString(numEmployees) as EmployeeRange)
        : undefined,
    },
    resolver: zodResolver(formSchema(market)),
  });

  useEffect(() => {
    (async () => {
      await loadRecaptcha();
      if (recaptchaRef.current) {
        renderRecaptcha(recaptchaRef.current);
      }
    })();
  }, []);

  const { terms, privacy } = getHelpLinks(market);
  return (
    <div>
      <div className={css.header}>
        <Heading level={3}>{t('self_signup.title')}</Heading>
        <Text size="sm">
          <Trans
            i18nKey="self_signup.already_signup"
            components={[
              <Link
                to={ROUTES.SIGNIN}
                key={ROUTES.SIGNIN}
                className={css.link}
              />,
            ]}
          />
        </Text>
      </div>
      <FormProvider {...methods}>
        <form
          className={css.form}
          onSubmit={methods.handleSubmit(handleSubmit)}
        >
          <div className={css.formRow}>
            <ControlledTextField
              name="company"
              label={t('self_signup.fields.company_label')}
            />
            <ControlledTextField
              name="taxId"
              label={t('self_signup.fields.nif_label')}
            />
          </div>
          <div className={css.formRow}>
            <ControlledTextField
              name="email"
              label={t('self_signup.fields.email_label')}
            />
            <ControlledSelectField
              name="employeeRange"
              options={EMPLOYEE_RANGE_VALUES}
              itemToString={(item) => (item ? item : '')}
              label={t('self_signup.fields.employee_range')}
            />
          </div>
          <Controller
            name="password"
            render={({ field: { ref, ...field }, fieldState }) => {
              const passwordHint = getPasswordHint(field.value);
              return (
                <FloatingPasswordField
                  {...field}
                  id="password"
                  label={t('self_signup.fields.password_label')}
                  hint={passwordHint}
                  error={fieldState.error?.message ? passwordHint : ''}
                />
              );
            }}
          />
          <ControlledPasswordField
            name="passwordConfirmation"
            label={t('self_signup.fields.confirm_password_label')}
          />
          <div className={css.actions}>
            {hasError && (
              <Alert type="error">{t('self_signup.generic_error')}</Alert>
            )}
            <Text size="sm">
              <Trans
                i18nKey="self_signup.disclaimer"
                components={[
                  <HelpLink i18nKey={terms} key="terms" className={css.link}>
                    &nbsp;
                  </HelpLink>,
                  <HelpLink
                    i18nKey={privacy}
                    key="privacy"
                    className={css.link}
                  >
                    &nbsp;
                  </HelpLink>,
                ]}
              />
            </Text>
            <ControllerContext
              render={({ formState: { isSubmitting } }) => (
                <Button
                  type="submit"
                  loading={isSubmitting}
                  className={css.button}
                >
                  {t('self_signup.confirm_button')}
                </Button>
              )}
            />
          </div>
          <div ref={recaptchaRef} />
        </form>
      </FormProvider>
    </div>
  );
};
