import { useHistory } from 'react-router-dom';
import { MARKET, PLAN } from 'constants/markets';
import { ROUTES } from 'constants/routes';
import { SignupForm } from 'containers/Authentication/components/SignupForm/SignupForm';
import { useAuth } from 'contexts/AuthProvider';
import { AuthenticationLayout } from 'layouts/AuthenticationLayout/AuthenticationLayout';
import { ISignupManager } from 'types/auth';

export const ItalySignup = () => {
  const { updateSession } = useAuth();
  const history = useHistory();
  const onFormSubmit = (manager: ISignupManager) => {
    if (manager.accessToken && manager.refreshToken) {
      updateSession(manager.accessToken, manager.refreshToken);
      history.push(ROUTES.ONBOARDING);
    }
  };
  return (
    <AuthenticationLayout>
      <SignupForm
        onSubmit={onFormSubmit}
        plan={PLAN.ITALY}
        market={MARKET.IT}
      />
    </AuthenticationLayout>
  );
};
