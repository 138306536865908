import JWT from 'jwt-decode';
import { TokenData } from 'types/menhir';

interface Token {
  big_company?: boolean;
  sub: string;
  companies?: string[];
}

export const TOKEN = 'cvrflx_apollo_token';
export const REFRESH_TOKEN = 'cvrflx_apollo_refresh_token';
export const USER_AGENT_TOKEN = 'cvrflx_apollo_user_agent_token';
export const SESSION_COMPANY_ID_KEY = 'cvrflx_session_company_id';
export const LAST_COMPANY_ID_KEY = 'cvrflx_last_company_id';
export const STORAGE_KEY = 'cvrflx_apollo_storage';
export const STORAGE_OPTIONS = {
  UPSELL_BANNER_DISMISSED: 'upsell_banner_dismissed',
  UPSELL_BANNER_COPY_VARIATION: 'upsell_banner_copy_variation',
  ES_MEAL_RESTRICTED_TO_LUNCH_HOURS: 'ES_MEAL_RESTRICTED_TO_LUNCH_HOURS',
} as const;

export const memoryStorage = (() => {
  const storage: Record<string, string> = {};
  return {
    setItem: (key: string, data: string) => {
      storage[key] = data;
    },
    getItem: (key: string) => storage[key],
    removeItem: (key: string) => {
      delete storage[key];
    },
  };
})();

const getStorage = () => {
  try {
    return localStorage;
  } catch {
    return memoryStorage;
  }
};

const getSessionStorage = () => {
  try {
    return sessionStorage;
  } catch {
    return memoryStorage;
  }
};

const getAuthToken = () => getStorage().getItem(TOKEN);

const setAuthToken = (token: string) => getStorage().setItem(TOKEN, token);

const clearAuthToken = () => {
  if (import.meta.env.VITE_MOCK) {
    window.dispatchEvent(new Event('clearAuthToken'));
  }
  getStorage().removeItem(TOKEN);
};

const decodeAuthToken = (token: string): TokenData | null => {
  try {
    const decoded: Token = JWT(token);
    const tokenData = JSON.parse(decoded.sub);
    return {
      ...tokenData,
      big_company: decoded.big_company ?? false,
      companies: decoded.companies ?? [],
    };
  } catch {
    return null;
  }
};

const getTokenData = () => {
  const token = getAuthToken();
  if (token) {
    return decodeAuthToken(token);
  }

  return null;
};

const getRefreshToken = () => getStorage().getItem(REFRESH_TOKEN);

const setRefreshToken = (token: string) =>
  getStorage().setItem(REFRESH_TOKEN, token);

export const getUserAgentToken = () => getStorage().getItem(USER_AGENT_TOKEN);

export const setUserAgentToken = (token: string) =>
  getStorage().setItem(USER_AGENT_TOKEN, token);

export const clearUserAgentToken = () =>
  getStorage().removeItem(USER_AGENT_TOKEN);

const clearRefreshToken = () => getStorage().removeItem(REFRESH_TOKEN);

const updateTokens = (
  authToken: string,
  refreshToken: string,
  userAgentToken?: string
) => {
  setAuthToken(authToken);
  setRefreshToken(refreshToken);
  if (userAgentToken !== undefined) {
    setUserAgentToken(userAgentToken);
  }
};

const clearTokens = () => {
  clearAuthToken();
  clearRefreshToken();
};

const getLastCompanyId = () => getStorage().getItem(LAST_COMPANY_ID_KEY);
const setLastCompanyId = (value: string) =>
  getStorage().setItem(LAST_COMPANY_ID_KEY, value);

const getSessionCompanyId = () =>
  getSessionStorage().getItem(SESSION_COMPANY_ID_KEY);
const setSessionCompanyId = (value: string) =>
  getSessionStorage().setItem(SESSION_COMPANY_ID_KEY, value);

const updateCompanyId = (companyId: string) => {
  setSessionCompanyId(companyId);
  setLastCompanyId(companyId);
};

export type TStorageOption =
  (typeof STORAGE_OPTIONS)[keyof typeof STORAGE_OPTIONS];
export const setStorageItem = (key: TStorageOption, value: string) => {
  const storage = getStorage().getItem(STORAGE_KEY) ?? '{}';
  let storageData: {
    [key in TStorageOption]?: string;
  } = {};
  try {
    storageData = JSON.parse(storage);
  } catch {}

  storageData[key] = value;
  getStorage().setItem(STORAGE_KEY, JSON.stringify(storageData));
};

export const getStorageItem = (key: TStorageOption) => {
  const storage = getStorage().getItem(STORAGE_KEY) ?? '{}';

  try {
    return JSON.parse(storage)?.[key];
  } catch {
    return undefined;
  }
};

const getCurrentCompanyId = () => {
  const sessionCompanyId = getSessionCompanyId();
  const tokenData = getTokenData();
  return sessionCompanyId ?? tokenData?.company_id;
};

const checkIfAllowedCompany = (tokenData: TokenData, companyId: string) =>
  tokenData.companies.includes(companyId);

// if there is no session id, reuse last company id
const initSession = () => {
  const tokenData = getTokenData();

  if (tokenData) {
    const sessionCompanyId = getSessionCompanyId();
    const lastCompanyId = getLastCompanyId();
    if (
      sessionCompanyId &&
      checkIfAllowedCompany(tokenData, sessionCompanyId)
    ) {
      setLastCompanyId(sessionCompanyId);
    } else if (
      !sessionCompanyId &&
      lastCompanyId &&
      checkIfAllowedCompany(tokenData, lastCompanyId)
    ) {
      setSessionCompanyId(lastCompanyId);
    } else {
      updateCompanyId(tokenData.company_id);
    }
  }
};

export const LocalStorage = {
  getAuthToken,
  setAuthToken,
  clearAuthToken,
  decodeAuthToken,
  getRefreshToken,
  setRefreshToken,
  clearRefreshToken,
  getUserAgentToken,
  setUserAgentToken,
  clearUserAgentToken,
  updateTokens,
  clearTokens,
  getTokenData,
  getLastCompanyId,
  setLastCompanyId,
  getSessionCompanyId,
  setSessionCompanyId,
  getCurrentCompanyId,
  updateCompanyId,
  initSession,
  setStorageItem,
  getStorageItem,
};

export default LocalStorage;
