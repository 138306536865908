import { useEffect, useRef } from 'react';
import AnalyticsService from 'services/analytics';
import { ITrackingPageViewTraits, TrackingPageNameType } from 'types/tracking';

export function usePageViewTracking<T extends TrackingPageNameType>(
  pageName: T,
  metadata?: ITrackingPageViewTraits[T]
) {
  const metadataRef = useRef(metadata);
  useEffect(() => {
    AnalyticsService.trackPageView(pageName, metadataRef.current);
  }, [pageName]);
}

export default usePageViewTracking;
