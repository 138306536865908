import { parsePhoneNumber } from 'libphonenumber-js';

export const parsePhone = (value: string) => {
  try {
    const parsedPhone = parsePhoneNumber(value);
    return {
      dialCode: `+${parsedPhone.countryCallingCode}`,
      number: parsedPhone.nationalNumber,
    };
  } catch {
    return null;
  }
};

export const phoneNumberToString = (phone: {
  dialCode: string;
  number: string;
}) => {
  return `${phone.dialCode}${phone.number}`;
};
