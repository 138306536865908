import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Text } from '@coverflex-tech/hypernova';
import AnalyticsService from 'services/analytics';
import { AuthService } from 'services/auth';
import { css } from './ResendAction.css';

interface IProps {
  email: string;
  password: string;
}

const getTimeLeft = (totalSeconds: number) => {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  return `${minutes < 10 ? '0' : ''}${minutes}:${
    seconds < 10 ? '0' : ''
  }${seconds}`;
};

export const ResendAction = ({ email, password }: IProps) => {
  const { t } = useTranslation();
  const [resendTimeout, setResendTimeout] = useState(0);
  const [resending, setResending] = useState(false);
  const intervalRef = useRef<number>();

  const onStartInterval = () => {
    setResendTimeout(60);
    intervalRef.current = window.setInterval(() => {
      setResendTimeout((prev) => {
        const newVal = prev - 1;
        if (newVal <= 0) {
          window.clearInterval(intervalRef.current);
        }
        return newVal;
      });
    }, 1000);
  };

  const onResend = async () => {
    try {
      setResending(true);
      await AuthService.signIn({ email, password });
      AnalyticsService.trackEvent('login_otp_resend_clicked');
      onStartInterval();
    } catch {
      //TODO: what to throw
    } finally {
      setResending(false);
    }
  };

  useEffect(() => {
    onStartInterval();
    return () => window.clearInterval(intervalRef.current);
  }, []);

  return (
    <div className={css.host}>
      {resendTimeout <= 0 ? (
        <div className={css.action}>
          <Text size="sm" as="span" color="neutral20">
            {t('signin.otp.resend_label')}
          </Text>
          <Button
            variant="tertiary"
            size="sm"
            onClick={onResend}
            loading={resending}
          >
            <Text size="sm" as="span" color="neutral20" underline>
              {t('signin.otp.resend_btn')}
            </Text>
          </Button>
        </div>
      ) : (
        <div className={css.resend}>
          <Text size="sm" color="neutral20">
            {t('signin.otp.resend_countdown', {
              time: getTimeLeft(resendTimeout),
            })}
          </Text>
          <Text size="sm" color="neutral20">
            <Trans
              i18nKey="signin.otp.help"
              components={[
                <Text as="span" underline bold key="otp_help" color="neutral20">
                  &nbsp;
                </Text>,
              ]}
            />
          </Text>
        </div>
      )}
    </div>
  );
};
