import { ReactNode } from 'react';
import { DefaultToastOptions, Toaster } from 'react-hot-toast';

export const ToastProvider = ({ children, options }: IProps) => (
  <>
    {children}
    <Toaster position="top-right" toastOptions={options} />
  </>
);

interface IProps {
  children: ReactNode;
  options?: DefaultToastOptions;
}
