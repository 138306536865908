import { MARKET, TMarket } from 'constants/markets';

export const getHelpLinks = (
  market: TMarket
): {
  terms: string;
  privacy: string;
} => {
  switch (market) {
    case MARKET.IT:
      return {
        terms: 'help_link.italy.terms_conditions',
        privacy: 'help_link.italy.privacy_policy',
      };
    case MARKET.PT:
    default:
      return {
        terms: 'help_link.suc_meal.terms_conditions',
        privacy: 'help_link.suc_meal.privacy_policy',
      };
  }
};
