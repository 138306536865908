import { useLocation } from 'react-router-dom';
import { PLAN, TPlan } from 'constants/markets';
import { WalletSignUp } from 'containers/Authentication/containers/SignUp/containers/WalletSignup/WalletSignUp';
import { AuthenticationLayout } from 'layouts/AuthenticationLayout/AuthenticationLayout';
import { parse } from 'query-string';
import { SelfSignUp } from './containers/SelfSignUp/SelfSignUp';

export const SignUp = () => {
  const location = useLocation();
  const parsedSearch = parse(location.search);

  const variant = parsedSearch.plan === 'meal' ? 'meal' : PLAN.WALLET;

  const token = Array.isArray(parsedSearch.token)
    ? parsedSearch.token[0]
    : parsedSearch.token ?? '';

  // not 100% certain if this is needed but it seems like sso flow is there even if token isn't valid
  const isSSO =
    typeof parsedSearch.auth === 'string' && parsedSearch.auth === 'sso';

  const isWalletSignup = variant === PLAN.WALLET && (isSSO || !!token);
  const plan: TPlan = variant === 'meal' ? PLAN.SUC_MEAL : PLAN.WALLET;

  return (
    <AuthenticationLayout>
      {isWalletSignup ? (
        <WalletSignUp token={token} isSSO={isSSO} />
      ) : (
        <SelfSignUp plan={plan} />
      )}
    </AuthenticationLayout>
  );
};
