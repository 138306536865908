import integrationsDTO from 'dto/integrations';
import { api } from 'integrations/menhir/api-client';
import { IMenhirIntegrations } from 'types/menhir';

interface IIntegrationsEnvelope {
  external_integrations: IMenhirIntegrations;
}

export const PREFIX = '/external-integrations';
export const API_ROUTES = {
  INTEGRATIONS: `${PREFIX}`,
};

export const getIntegrations = () =>
  api
    .get<null, IIntegrationsEnvelope>(API_ROUTES.INTEGRATIONS)
    .then((envelope) =>
      integrationsDTO.transformIntegrations(envelope.external_integrations)
    );

export default {
  getIntegrations,
};
