import { fileApi } from 'integrations/menhir/api-client';
import { IFileBlob } from 'types/general';

const PREFIX = '/business/digital-archive';

export const API_ROUTES = {
  DOWNLOAD_FILE: (fileId: string) => `${PREFIX}/${fileId}`,
};

const downloadFile = (fileId: string) =>
  fileApi.get<null, IFileBlob>(API_ROUTES.DOWNLOAD_FILE(fileId), {
    responseType: 'blob',
  });

export default {
  downloadFile,
};
