import { api } from 'integrations/menhir/api-client';

const PREFIX = '/business/files';

export const API_ROUTES = {
  FILE: (fileId: string) => `${PREFIX}/${fileId}`,
};

export const getFile = (fileId: string) =>
  api.get<null, { url: string }>(API_ROUTES.FILE(fileId));

export default {
  getFile,
};
