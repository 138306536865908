import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  className?: string;
  i18nKey: string;
  queryParams?: {
    [key: string]: string;
  };
  children: ReactNode;
  onClick?: () => void;
}

export const HelpLink = ({
  className,
  i18nKey,
  queryParams,
  children,
  onClick,
}: IProps) => {
  const { t } = useTranslation();

  let href = t(i18nKey);
  if (queryParams) {
    href = `${href}?${new URLSearchParams(queryParams).toString()}`;
  }

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={href}
      className={className}
      onClick={onClick}
    >
      {children}
    </a>
  );
};

export default HelpLink;
