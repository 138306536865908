import { MARKET, TMarket } from 'constants/markets';
import { REGEX_EMAIL } from 'constants/regex';
import { EmployeeRange } from 'types/auth';
import { isPasswordValid } from 'utils/password';
import { validateNif, validatePartitaIVACompany } from 'utils/tax';
import { requiredString } from 'utils/zod-helpers';
import { z } from 'zod';

export const EMPLOYEE_RANGE_VALUES: EmployeeRange[] = [
  '1-10',
  '11-25',
  '26-50',
  '51-100',
  '101-250',
  '250+',
];

export const formSchema = (market: TMarket) => {
  return z
    .object({
      company: requiredString,
      taxId: requiredString.refine(
        (value) => {
          if (market === MARKET.PT) return validateNif(value);
          else if (market === MARKET.IT)
            return validatePartitaIVACompany(value);
          return true;
        },
        {
          message: 'signup.errors.invalid_nif',
        }
      ),
      taxType: z.enum(['nif', 'partita_iva']),
      email: requiredString.regex(REGEX_EMAIL, 'signup.errors.invalid_email'),
      password: requiredString,
      passwordConfirmation: requiredString,
      employeeRange: z.custom<EmployeeRange>(
        (val) => EMPLOYEE_RANGE_VALUES.includes(val as EmployeeRange),
        { message: 'form_errors.required' }
      ),
    })
    .superRefine((data, ctx) => {
      if (!isPasswordValid(data.password)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'authentication.create_password.hint',
          path: ['password'],
        });
      }
      if (data.password !== data.passwordConfirmation) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'authentication.create_password.errors.passwords_dont_match',
          path: ['passwordConfirmation'],
        });
      }
    });
};

export type ISignupFormData = z.infer<ReturnType<typeof formSchema>>;
