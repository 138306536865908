import { useCallback } from 'react';
import { API_ROUTES, SubscriptionService } from 'services/subscription';
import useSWR from 'swr';

export const useSubscription = () => {
  const { data: subscription, mutate } = useSWR(API_ROUTES.SUBSCRIPTION, () =>
    SubscriptionService.getSubscription()
  );

  const requestSubscriptionCancellation = useCallback(async () => {
    await SubscriptionService.requestSubscriptionCancellation();
    mutate();
  }, [mutate]);

  return {
    loading: subscription === undefined,
    subscription,
    requestSubscriptionCancellation,
  };
};

export default useSubscription;
