import { BulletsLoading } from '@coverflex-tech/hypernova';
import css from './LoadingPlaceholder.module.css';

export const LoadingPlaceholder = () => {
  return (
    <div className={css.host} data-test-id="loading-placeholder">
      <BulletsLoading />
    </div>
  );
};
