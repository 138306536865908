import { api } from 'integrations/menhir/api-client';
import { IMenhirOAuthAuthenticate } from 'types/menhir';
import authDTO from '../dto/auth';
import localStorageService from './local-storage';

interface AuthResponse {
  token: string;
  refresh_token: string;
}

export const PREFIX = '/business/sessions/sso';
export const API_ROUTES = {
  AUTHENTICATE: `${PREFIX}/authenticate`,
  AUTHORIZE: `${PREFIX}/authorize`,
};

const onSuccess = ({ token, refresh_token }: AuthResponse) => {
  localStorageService.updateTokens(token, refresh_token);
  const authData = localStorageService.decodeAuthToken(token);
  if (authData) {
    localStorageService.initSession();

    return authDTO.tokenToUser(authData);
  } else {
    throw new Error();
  }
};

const authenticate = (email: string) =>
  api.post<null, IMenhirOAuthAuthenticate>(API_ROUTES.AUTHENTICATE, {
    email,
  });

const authorize = (grant: string, state: string) =>
  api
    .post<null, AuthResponse>(API_ROUTES.AUTHORIZE, {
      grant,
      state,
    })
    .then(onSuccess);

export default {
  authenticate,
  authorize,
};
