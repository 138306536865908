import { ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

export const queryClient = (() =>
  new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        staleTime: 5000,
        refetchOnWindowFocus: false,
      },
    },
  }))();

export interface IProps {
  client?: QueryClient;
  children: ReactNode;
}

export const ReactQueryProvider = ({
  client = queryClient,
  children,
}: IProps) => (
  <QueryClientProvider client={client}>{children}</QueryClientProvider>
);
