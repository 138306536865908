import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import { useAuth } from 'contexts/AuthProvider';

interface ILocationState {
  walletSetupOrigin?: boolean;
}

export const PrivateRoute = ({ children, path, ...props }: RouteProps) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation<ILocationState>();

  if (!isAuthenticated) {
    return <Redirect to={{ pathname: ROUTES.SIGNIN, state: location.state }} />;
  }

  return <Route {...props}>{children}</Route>;
};
