import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { api, rawApi } from 'integrations/menhir/api-client';
import { ISigninResponse, ISignupRequest, LoginData } from 'types/auth';
import { IMenhirSignupManager, MenhirUser } from 'types/menhir';
import { AuthResponse, MenhirLoginData } from 'types/menhir/auth';
import authDTO from '../dto/auth';
import { logoutChatUser } from './analytics';
import localStorageService from './local-storage';

interface IResetPasswordRequest {
  email: string;
}

interface IConfirmPasswordRequest {
  token: string;
  password: string;
}

export const PREFIX = '/business';
export const API_ROUTES = {
  SESSIONS: `${PREFIX}/sessions`,
  REGISTRATION: `${PREFIX}/register`,
  SIGNUP: `${PREFIX}/signup`,
  ME: `${PREFIX}/me`,
  PASSWORD_RESET: '/users/password_reset',
  RENEW: `${PREFIX}/sessions/renew`,
  RESEND_CONFIRMATION: (managerId: string) =>
    `${PREFIX}/signup/${managerId}/resend-confirmation`,
  CONFIRM_ACCOUNT: `${PREFIX}/signup/confirm`,
  RESEND_TOKEN: `${PREFIX}/resend`,
  TRUST_USER_AGENT: `${PREFIX}/sessions/trust-user-agent`,
};

const updateTokens = ({
  token,
  refresh_token,
  user_agent_token,
}: AuthResponse) => {
  localStorageService.updateTokens(token, refresh_token, user_agent_token);
  const authData = localStorageService.decodeAuthToken(token);
  if (authData) {
    localStorageService.initSession();
    return authDTO.tokenToUser(authData);
  } else {
    throw new Error();
  }
};

const signIn = ({
  email,
  password,
  otp,
  trustUserAgent: trustUserAgentCheck,
}: LoginData): Promise<ISigninResponse> => {
  return rawApi
    .post<any, AxiosResponse<AuthResponse>, MenhirLoginData>(
      API_ROUTES.SESSIONS,
      {
        email,
        password,
        otp,
        user_agent_token: localStorageService.getUserAgentToken() || undefined,
      }
    )
    .then(async (response) => {
      if (response.status === 202) {
        return {
          reason: 'OTP_REQUIRED',
          channel: response.data.channel,
          phoneLastDigits: response.data.phone_last_digits,
          email,
          password,
        };
      }

      if (trustUserAgentCheck) {
        updateTokens(response.data);
        return await trustUserAgent();
      }

      return updateTokens(response.data);
    });
};

const validateInvitation = (token: string) => {
  return api.post<AuthResponse, AuthResponse>(API_ROUTES.REGISTRATION, {
    registration: {
      invitation_code: token,
    },
  });
};

const signUp = (token: string, password: string) => {
  return api.post<AuthResponse, AuthResponse>(API_ROUTES.REGISTRATION, {
    registration: {
      invitation_code: token,
      password,
    },
  });
};

const companySignUp = async (request: ISignupRequest, token: string) =>
  api
    .post<null, { manager: IMenhirSignupManager }>(
      API_ROUTES.SIGNUP,
      {
        company: {
          name: request.company,
          tax_id: {
            value: request.taxId,
            type: request.taxType,
          },
          market: request.market,
          plan: request.plan,
          language: request.language,
          employee_range: request.employeeRange,
        },
        manager: {
          email: request.email,
          password: request.password,
          password_confirmation: request.passwordConfirmation,
        },
      },
      {
        headers: {
          'x-coverflex-recaptcha': token,
        },
      }
    )
    .then((envelope) => authDTO.transformSignupManager(envelope.manager));

const resendEmailConfirmation = (managerId: string) =>
  api.post(API_ROUTES.RESEND_CONFIRMATION(managerId));

const confirmAccount = (token: string) =>
  api.post(API_ROUTES.CONFIRM_ACCOUNT, { token: token });

const resendEmailToken = (token: string) =>
  api.post(API_ROUTES.RESEND_TOKEN, { token });

export const trustUserAgent = (): Promise<ISigninResponse> => {
  return api
    .post<undefined, AuthResponse>(API_ROUTES.TRUST_USER_AGENT)
    .then((data) => {
      return updateTokens(data);
    });
};

const logout = () => {
  localStorageService.clearTokens();
  logoutChatUser();
};

const requestResetPassword = (email: string) => {
  return api.post<IResetPasswordRequest, null>(API_ROUTES.PASSWORD_RESET, {
    email,
  });
};

const confirmNewPassword = (token: string, password: string) => {
  return api.put<IConfirmPasswordRequest, null>(API_ROUTES.PASSWORD_RESET, {
    token,
    password,
  });
};

const checkIfAuthenticated = () => !!localStorageService.getAuthToken();

export const getUserInfo = (config?: AxiosRequestConfig) =>
  api
    .get<null, MenhirUser>(API_ROUTES.ME, config)
    .then(authDTO.transformUserInfo);

export const AuthService = {
  signIn,
  validateInvitation,
  signUp,
  companySignUp,
  logout,
  requestResetPassword,
  confirmNewPassword,
  checkIfAuthenticated,
  getUserInfo,
  resendEmailConfirmation,
  confirmAccount,
  resendEmailToken,
  updateTokens,
};

export default AuthService;
