import {
  transformDedicatedIBANFeatureResponse,
  transformFeatures,
} from 'dto/features';
import { api } from 'integrations/menhir/api-client';
import useSWR from 'swr';
import { IDedicatedIBANFeature, IFeatures } from 'types/features';
import { SWRConfigurationExtended } from 'types/general';
import {
  IMenhirDedicatedIBANFeature,
  IMenhirFeatures,
} from 'types/menhir/features';

export const PREFIX = '/business';
export const API_ROUTES = {
  FEATURES: `${PREFIX}/features`,
  DEDICATED_IBAN_FEATURE: `${PREFIX}/features/dedicated-iban`,
};

export const getFeatures = (): Promise<IFeatures> =>
  api
    .get<null, { features: IMenhirFeatures }>(API_ROUTES.FEATURES)
    .then((envelope) => transformFeatures(envelope.features));

export const getDedicatedIBANFeature = (): Promise<IDedicatedIBANFeature> =>
  api
    .get<
      null,
      { dedicated_iban: IMenhirDedicatedIBANFeature }
    >(API_ROUTES.DEDICATED_IBAN_FEATURE)
    .then((envelope) =>
      transformDedicatedIBANFeatureResponse(envelope.dedicated_iban)
    );

export const useGetDedicatedIBANFeature = (
  options?: SWRConfigurationExtended
) => {
  return useSWR<IDedicatedIBANFeature>(
    options?.enabled !== false ? API_ROUTES.DEDICATED_IBAN_FEATURE : null,
    getDedicatedIBANFeature,
    options
  );
};

export default {
  getFeatures,
  getDedicatedIBANFeature,
  useGetDedicatedIBANFeature,
};
