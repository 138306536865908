import { initReactI18next } from 'react-i18next';
import { removeFalsey } from '@coverflex-tech/hypernova-utils';
import { LANGUAGES } from 'constants/languages';
import i18n from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { LanguageType } from 'types/general';

const transformNavigatorLanguage = (lang: string): LanguageType => {
  const prefix = lang.split('-')[0];
  switch (prefix) {
    case 'pt':
      return 'pt-PT';
    case 'it':
      return 'it-IT';
    case 'es':
      return 'es-ES';
    case 'en':
    default:
      return 'en-GB';
  }
};

export const getDefaultLang = () => {
  return transformNavigatorLanguage(navigator.language);
};

const getFileName = (language: string) => language.replace('-', '_');

const backends = [
  import.meta.env.PROD && HttpBackend,
  resourcesToBackend((language, _, callback) => {
    import(`./translations/${getFileName(language)}.json`)
      .then(({ default: resources }) => {
        callback(null, resources);
      })
      .catch((error) => {
        callback(error, null);
      });
  }),
].filter(removeFalsey);

i18n
  .use(ChainedBackend)
  .use(initReactI18next)
  .init({
    backend: {
      backends,
      backendOptions: [
        {
          crossDomain: true,
          loadPath: (lng: LanguageType[]) => {
            const language = lng[0];
            if (LANGUAGES.includes(language)) {
              return `${import.meta.env.VITE_LOKALISE_BUCKET_URL}${getFileName(
                language
              )}.json`;
            }
          },
        },
      ],
    },
    lng: getDefaultLang(),
    fallbackLng: 'en-GB',
    interpolation: {
      escapeValue: false,
    },
  });

export { i18n };
