import { useCallback, useMemo } from 'react';
import { ICountrySettings, countriesList } from 'constants/countries';
import { i18n } from 'i18n';
import { LanguageType } from 'types/general';
import { z } from 'zod';

export const countrySchema = z.object(
  {
    name: z.string(),
    iso2: z.string(),
    dialCode: z.string(),
    suggested: z.boolean().optional(),
  },
  { required_error: 'form_errors.required' }
);

export type ICountry = z.infer<typeof countrySchema>;

const DEFAULT_LANGUAGE = 'en-GB' as const;

export const getCountryName = (
  countryCode: ICountrySettings['iso2'],
  language: `${string}-${string}` = i18n.language as LanguageType
) => {
  // For the case where this Browser API is not yet supported
  try {
    const countryNames = new Intl.DisplayNames([language ?? DEFAULT_LANGUAGE], {
      type: 'region',
    });
    const candidateCountryName = countryNames.of(countryCode);
    if (!candidateCountryName) {
      throw new Error('Country not found');
    }
    return candidateCountryName;
  } catch {
    const country = countriesList.find((c) => c.iso2 === countryCode);
    return country?.name[language] ?? '';
  }
};

const baseCountries: ICountry[] = countriesList.map((country) => ({
  name: getCountryName(country.iso2) ?? country.name[DEFAULT_LANGUAGE],
  iso2: country.iso2,
  dialCode: country.dialCode ?? '',
}));

export const getCountryByCode = (countryCode?: string): ICountry | undefined =>
  countryCode ? baseCountries.find((c) => c.iso2 === countryCode) : undefined;

export function useCountries(suggestedCountry = 'PT') {
  const countries = useMemo<ICountry[]>(
    () =>
      baseCountries.map((country) => ({
        ...country,
        suggested: country.iso2 === suggestedCountry,
      })),
    [suggestedCountry]
  );

  const countryToString = useCallback(
    (country: ICountry | null) => country?.name ?? '',
    []
  );

  const searchCountry = useCallback(
    (country: ICountry, searchTerm: string) =>
      country.name.toLowerCase().startsWith(searchTerm.toLowerCase()),
    []
  );

  return {
    countries,
    getCountryByCode,
    countryToString,
    searchCountry,
  };
}

export default useCountries;
