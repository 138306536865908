import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, LogoCoverflex } from '@coverflex-tech/hypernova';
import { ROUTES } from 'constants/routes';
import { css } from './PageNotFound.css';

export const PageNotFound = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={css.root}>
        <div className={css.header}>
          <LogoCoverflex />
        </div>
        <div className={css.content}>
          <div className={css.errorCode}>{t('page_not_found.error_code')}</div>
          <div className={css.title}>{t('page_not_found.title')}</div>
          <div className={css.description}>
            {t('page_not_found.description')}
          </div>
          <Link to={ROUTES.ROOT}>
            <Button presentational>
              {t('general.errors.generic_error.go_home')}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
