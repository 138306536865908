import { Suspense } from 'react';
import { Redirect } from 'react-router-dom';
import { ERRORS } from 'constants/errors';
import { MARKET } from 'constants/markets';
import { ROUTES } from 'constants/routes';
import { useUser } from 'contexts/AuthProvider';
import { GlobalDashboardAlertsProvider } from 'contexts/GlobalDashboardAlertsProvider/GlobalDashboardAlertsProvider';
import { ICompanyProfile } from 'types/company';
import { safeLazy } from 'utils/import';
import { LoadingPlaceholder } from 'components/LoadingPlaceholder/LoadingPlaceholder';

const { ITDashboardRoutes } = safeLazy(
  () => import('./ITDashboard/ITDashboardRoutes')
);
const { PTDashboardRoutes } = safeLazy(
  () => import('./PTDashboard/PTDashboardRoutes')
);
const { ESDashboardRoutes } = safeLazy(
  () => import('./ESDashboard/ESDashboardRoutes')
);

export const Dashboard = () => {
  const { user } = useUser();

  if (!user.company.onboarded) {
    return <Redirect to={ROUTES.ONBOARDING} />;
  }

  const dashboardComponent = getDashboardComponent(user.company);

  return (
    <Suspense fallback={<LoadingPlaceholder />}>
      <GlobalDashboardAlertsProvider>
        {dashboardComponent}
      </GlobalDashboardAlertsProvider>
    </Suspense>
  );
};

const getDashboardComponent = (company: ICompanyProfile) => {
  switch (company.market.slug) {
    case MARKET.PT:
      return <PTDashboardRoutes />;
    case MARKET.IT:
      return <ITDashboardRoutes plan={company.plan} />;
    case MARKET.ES:
      return <ESDashboardRoutes />;
    default:
      throw new Error(ERRORS.MARKET_NOT_SUPPORTED);
  }
};
