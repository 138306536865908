import * as Sentry from '@sentry/react';
import { RouterHistory } from '@sentry/react/types/reactrouter';

const setupReporter = (history: RouterHistory) => {
  Sentry.init({
    environment: import.meta.env.VITE_ENV,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],

    tracesSampleRate: 0.1,
  });
};

export default {
  setupReporter,
};
