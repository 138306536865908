import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useLocation } from 'react-router-dom';
import { LANGUAGES } from 'constants/languages';
import { ROUTES } from 'constants/routes';
import { parse } from 'query-string';
import { LanguageType } from 'types/general';
import { PublicRoute } from 'components/PublicRoute';
import { ItalySignup } from './containers/ItalySignup/ItalySignup';
import { Login } from './containers/Login/Login';
import { OAuthLogin } from './containers/OAuthLogin/OAuthLogin';
import { ResetPassword } from './containers/ResetPassword/ResetPassword';
import { SignUp } from './containers/SignUp/SignUp';
import SignupEmailConfirmation from './containers/SignupEmailConfirmation';

export const AuthenticationRoutes = () => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const { language } = parse(location.search);

  useEffect(() => {
    if (
      typeof language === 'string' &&
      LANGUAGES.includes(language as LanguageType)
    ) {
      i18n.changeLanguage(language);
    }
  }, [language, i18n]);

  return (
    <Switch>
      <PublicRoute path={ROUTES.SIGNIN}>
        <Login />
      </PublicRoute>
      <PublicRoute path={ROUTES.SSO_SIGNIN}>
        <OAuthLogin />
      </PublicRoute>
      <Route path={ROUTES.SIGNUP_IT}>
        <ItalySignup />
      </Route>
      <Route path={ROUTES.SIGNUP}>
        <SignUp />
      </Route>
      <Route path={ROUTES.RESET_PASSWORD}>
        <ResetPassword />
      </Route>
      <Route path={ROUTES.EMAIL_CONFIRMATION}>
        <SignupEmailConfirmation />
      </Route>
    </Switch>
  );
};
